import { ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { SubSink } from 'subsink';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ContractVerificationService } from '@appServices/core-crm/contract-verification/contract-verification.service';

@Component({
  selector: 'app-map-shared',
  templateUrl: './map-shared.component.html',
  styleUrls: ['./map-shared.component.css'],
})
export class MapSharedComponent implements OnInit {
  private _sbS = new SubSink();
  contractVerificationService = inject(ContractVerificationService);
  @Input('latitude') latitude;
  @Input('longitude') longitude;
  @Input() installationData: any;
  @Input() id;

  zoom = 15; // Fixed zoom level
  mapUrl: SafeResourceUrl;
  installationsUrl: SafeResourceUrl;

  isLoading = true;
  showSalesMap = false;
  showInstallationsMap = false;

  constructor(private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    const customerId = this.id;
    this._sbS.sink = this.contractVerificationService
      .getContractDetails(customerId)
      .subscribe({
        next: (data) => {
          if (data?.installationData && data?.installationData?.latitude && data?.installationData?.longitude) {
            this.createInstallationMap(data?.installationData?.latitude, data?.installationData?.longitude);
            this.showInstallationsMap = true;
          }
          
          this.updateMapUrl();
          this.isLoading = false;
          this.cdr.detectChanges();
        },
        error: (err) => {
          console.error('Error fetching contract details', err);
          this.isLoading = true;
          this.cdr.detectChanges();
        },
      });
  }

  createInstallationMap(latitude, longitude) {
    const url = `https://maps.google.com/maps?q=${latitude},${longitude}&z=${this.zoom}&output=embed`;
    this.installationsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  updateMapUrl(): void {
    const url = `https://maps.google.com/maps?q=${this.latitude},${this.longitude}&z=${this.zoom}&output=embed`;
    this.mapUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnDestroy(): void {
    this._sbS.unsubscribe();
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { CoreAccountingService } from "@appServices/core-accounting/core-accounting.service";

@Injectable({ providedIn: "root" })
export class EscalationImagesService extends CoreAccountingService {
  constructor(private http: HttpClient) {
    super(http, "escalationInstallationGuideStep");
  }

  getInstallationPictures(attachmentId: number): Observable<any> {
    const url = `${this.base_url}/escalationInstallation/attachment/${attachmentId}`;
    return this.http.get<any>(url, { headers: this.headers,  responseType: 'blob' as 'json' }).pipe(
      tap((res) => {
        this.log(`res`);
      }),
      catchError(this.handleError("res", ""))
    );
  }
}

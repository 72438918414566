import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {CoreTicketingService} from "@appServices/core-ticketing/core-ticketing.service";

@Injectable({ providedIn: 'root' })
export class TicketDashboardService extends CoreTicketingService {

  constructor(
    private http: HttpClient,
  ) {
    super(http, 'ticketReporting');
    this.headers = this.headers.append('skip-cache', 'true');
  }


  getTypeStatusBreakdown(): Observable<any> {
    const url = `${this.base_url}/ticketTypeStatusBreakdown/${localStorage.getItem("tenant")}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(any => this.log(`fetched SmileIdentity`)),
      catchError(this.handleError<any>(`getSmileIdentity`, []))
    );
  }



}

export const environment = {
  production: true,
  base_url: 'https://coregateway.app.dlight.com',
  graph_url: "https://graphql.app.dlight.com/graphql",
  api_ver: 'api/v1',
  version: 'V4.1.0.969-Beta',
  name: 'prod',
  sentry_enabled: true,
  flexmonster_license: 'Z7KR-10IA5B-294G5N-3P6A4C-4I542P-6A2G26-534B2L-5B6T6R-1G6H64-6H4V46-051B56',
  base_web_url: 'https://atlas.app.dlight.com',
};

import { ProductCatalogue } from "@appModels/core-setup/product-catalogue/product-catalogue";

export function __mapToSelectOption__(item: { id: number, name: string }): { id: number, itemName: string } {
    return {
      id: item.id,
      itemName: item.name,
    };
  }
 
export function __convertOptions__(item:string) {
    return {
        id: item,     
        itemName: item     
    }
} 

export function __convertOptionsProducts__(item:ProductCatalogue) {
    return {
        id: item.id,     
        itemName: item.productDisplayName     
    }
} 

export function __convertCallStatus__(item:any) {
    return {
        id: item.id,     
        itemName: item.name     
    }
} 
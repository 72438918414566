import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {HomeComponent} from '@appContainers/home/home.component';
import {FAQComponent} from '@appContainers/static-resources/FAQ/faq.component';
import {PrivacyPolicyComponent} from '@appContainers/static-resources/privacy-policy/privacy-policy.component';
import {TermsAndConditionsComponent} from '@appContainers/static-resources/terms-and-conditions/terms-and-conditions.component';

import {AuthGuard, PublicGuard} from '@appGuards/core-auth/auth/auth.guard';
import {IdentityGuard} from '@appGuards/core-identity/identity.guard';
import {PhoneManagementGuard} from '@appGuards/core-phone-management/phone-management.guard';
import {CoreCrmGuard} from '@appGuards/core-crm/core-crm.guard';
import {CoreSetupGuard} from '@appGuards/core-setup/core-setup.guard';

import {TimezonesGuard} from '@appGuards/core-setup/timezone/timezone.guard';
import {HomeGuard} from '@appGuards/home.guard';
import {CoreInventoryGuard} from '@appGuards/core-inventory/core-inventory.guard';

import {BlankComponent} from '@appLayout/blank/blank.component';
import {MainComponent} from '@appLayout/main/main.component';
import {CoreFieldforceGuard} from "@appGuards/core-fieldforce/core-fieldforce.guard";
import {CoreFinanceGuard} from '@appGuards/core-finance/core-finance.guard';
import {TenantActivationComponent} from '@appContainers/activation/tenant-activation.component';
import {ModulesGuard} from '@appGuards/core-setup/module/module.guard';
import {CoreAftersalesGuard} from "./guards/core-aftersales/core-aftersales.guard";
import {CoreReportingGuard} from '@appGuards/core-reporting/core-reporting.guard';
import {CoreSoftLaunchGuard} from "./guards/core-soft-launch/core-soft-launch.guard";
import { CoreKnowledgeGuard } from '@appGuards/core-knowledge/core-knowledge.guard';
import { SepDashboardComponent } from '@appContainers/sep-dashboard/sep-dashboard.component';
import { CoreGlobalSettingsGuard } from '@appGuards/core-global-settings/core-global-settings.guard';
import { CoreCommunicationGuard } from '@appGuards/core-communication/core-communication.guard';
import {CoreTicketingGuard} from "@appGuards/core-ticketing/core-ticketing.guard";

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [HomeGuard],
      },
      {
        path: 'activation',
        component: TenantActivationComponent,
        canActivate: [TimezonesGuard]
      },
      {
        path: 'faq',
        component: FAQComponent,
        canActivate: [],
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        canActivate: [],
      },
      {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent,
        canActivate: [],
      },
      {
        path: 'dashboard-sep',
        component: SepDashboardComponent,
        canActivate: [],
      },
      {
        path: 'crm',
        canActivate: [CoreCrmGuard],
        loadChildren: () => import('./containers/crm/crm.module').then(m => m.CrmModule)
      },
      {
        path: 'identity-management',
        canActivate: [IdentityGuard],
        loadChildren: () => import('./containers/identity-management/identity-management.module').then(m => m.IdentityManagementModule)
      },
      {
        path: 'phone-management',
        canActivate: [PhoneManagementGuard],
        loadChildren: () => import('./containers/phone-management/phone-management.module').then(m => m.PhoneManagementModule)
      },
      {
        path: 'inventory',
        canActivate: [CoreInventoryGuard],
        loadChildren: () => import('./containers/inventory/inventory.module').then(m => m.InventoryModule)
      },
      {
        path: 'fieldforce',
        canActivate: [CoreFieldforceGuard],
        loadChildren: () => import('./containers/fieldforce/fieldforce.module').then(m => m.FieldForceModule)
      },
      {
        path: 'reporting',
        canActivate: [CoreReportingGuard],
        loadChildren: () => import('./containers/reporting/reporting.module').then(m => m.ReportingModule)
      },
      {
        path: 'finance',
        canActivate: [CoreFinanceGuard],
        loadChildren: () => import('./containers/finance/finance.module').then(m => m.FinanceModule)
      },
      {
        path: 'setup',
        canActivate: [CoreSetupGuard],
        loadChildren: () => import('./containers/setup/setup.module').then(m => m.SetupModule)
      },
      {
        path: 'warranty-and-service',
        canActivate: [CoreAftersalesGuard],
        loadChildren: () => import('./containers/aftersales/aftersales.module').then(m => m.AftersalesModule)
      },
      {
        path: 'soft-launch',
        canActivate: [CoreSoftLaunchGuard],
        loadChildren: () => import('./containers/soft-launch/softlaunch.module').then(m => m.SoftLaunchModule)
      },
      {
        path: 'knowledge-base',
        canActivate: [CoreKnowledgeGuard],
        loadChildren: () => import('./containers/knowledge-base/knowledge-base.module').then(m => m.KnowledgeBaseModule)
      },
      {
        path: 'global-settings',
        canActivate: [CoreGlobalSettingsGuard],
        loadChildren: () => import('./containers/global-settings/global-settings.module').then(m => m.GlobalSettingsModule)
      },
      {
        path: 'communication',
        canActivate: [CoreCommunicationGuard],
        loadChildren: () => import('./containers/communication/communication.module').then(m => m.CommunicationModule)
      },
      {
        path: 'global-settings',
        canActivate: [CoreGlobalSettingsGuard],
        loadChildren: () => import('./containers/global-settings/global-settings.module').then(m => m.GlobalSettingsModule)
      },
      {
        path: 'communication',
        canActivate: [CoreCommunicationGuard],
        loadChildren: () => import('./containers/communication/communication.module').then(m => m.CommunicationModule)
      },
      {
        path: 'ticketing',
        canActivate: [CoreTicketingGuard],
        loadChildren: () => import('@appContainers/ticketing/ticketing-module').then(m => m.TicketingModule)
      },

      // {path: '**', redirectTo: 'home' }
    ],
    canActivate: [AuthGuard, ModulesGuard],
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {path: '', redirectTo: 'login', pathMatch: 'full'},
      {
        path: 'login',
        loadChildren: () => import('./containers/auth/auth.module').then(m => m.AuthModule)
      },
      {
        path: 'auth',
        loadChildren: () => import('./containers/auth/auth.module').then(m => m.AuthModule)
      },
      {
        path: 'invoice/:id',
        loadChildren: () => import('./containers/static-resources/fiscal-invoice/fiscal-invoice.module').then(m => m.FiscalInvoiceModule)
      },
      {
        path: 'creditnote/:id',
        loadChildren: () => import('./containers/static-resources/credit-note-invoice/credit-note-invoice.module').then(m => m.CreditNoteInvoiceModule)
      },
      {
        path: 'publicpages/:id',
        loadChildren: () => import('./containers/static-resources/public-pages/public-pages.module').then(m => m.PublicPagesModule)
      },
      {
        path: 'releaseinfo/:id',
        loadChildren: () => import('./containers/static-resources/release-info/release-info.module').then(m => m.ReleaseInfoModule)
      },
      {
        path: 'l/:uniqueIdentifier',
        loadChildren: () => import('./containers/static-resources/public-survey/public-survey.module').then(m => m.PublicSurveyModule)
      }
    ],
    canActivate: [PublicGuard]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

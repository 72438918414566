
<div class="flex flex-row justify-between my-8 mx-4">
  <div class="flex flex-row  justify-start gap-3" >
    <div class="text-xl text-black-dark-light">
      Home
    </div>
    <div class="text-xl font-black text-black-dark-light">
      /
    </div>
    <div class="text-xl text-black-dark-light text-orange-600">
      Dashboard
    </div>
  </div>

</div>



<div class="mt-10 grid grid-cols-2 gap-3 p-4 pt-10 ">
  <div *ngFor="let ticketType of ticketTypeStatusBreakdown.ticketTypes" class="grid grid-cols-4 "  style="margin-top:40px; box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;" >
      <div class="col-span-3">
        <div class="flex flex-row w-full justify-center ">
          <span class="text-lg text-black-dark-light text-orange-600">
            {{ticketType.ticketTypeName}}
            <div class="text-dlight">
            <hr class="h-px border-t-0 bg-transparent bg-gradient-to-r from-red-500 via-red-500 to-transparent opacity-25">
          </div>
          </span>
        </div>

        <div class="flex flex-row w-full px-5 mt-3 mb-3 gap-3">
          <ng-container *ngFor="let status of ticketType.statuses">

              <div class="flex-1 p-5 rounded-lg box-shadow-pop flex items-center justify-center" [ngStyle]="{'background': status.colorCode, 'color':'black'}">
                <div class="flex flex-col gap-2 w-full">
                  <div class="flex flex-row justify-center text-black text-sm">{{status.statusName}}</div>
                  <div class="flex flex-row justify-center text-neutral-600 text-sm">{{status.count}}</div>
                </div>
              </div>

          </ng-container>

        </div>

    </div>
    <div class=" col-span-1 flex-1 p-5 rounded-lg box-shadow-pop flex items-center justify-center bg-green-400 hover:bg-green-500" >
      <div class="flex flex-col gap-2 w-full">
        <div class="flex flex-row justify-center text-black font-semibold">Percentage Closed</div>
        <div class="flex flex-row justify-center text-black font-semibold">{{ticketType.percentageClosed * 100}} %</div>

      </div>
    </div>
  </div>
</div>

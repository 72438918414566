import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {AccessoryRate} from '@appModels/core-accounting/accessory-rate/accessory-rate';

import {CoreAccountingService} from '../core-accounting.service';


@Injectable({ providedIn: 'root' })
export class AccessoryRateService extends CoreAccountingService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'rate');
  }

  /** GET accessoryRates from the server */
  // getAccessoryRates(query?): Observable<AccessoryRate[]> {
  //   let params = new HttpParams();
  //   if (query !== undefined){
  //     // {page:1, size:10, sort: '' }
  //     Object.keys(query).forEach(val => {
  //       params = params.append(val, query[val]);
  //     });
  //   }
  //   return this.http.get<any>(this.base_url + '/', { headers: this.headers }).pipe(
  //     tap(accessoryRates => this.log(`fetched accessoryRates`)),
  //     catchError(this.handleError('getAccessoryRates', []))
  //   );
  // }


  getAdditionalDetails(id: number) {
    const url = `${this.base_url}/${id}/additionalDetails`;
    return this.http.get<AccessoryRate>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched accessoryRate id=${id}`)),
      catchError(this.handleError<AccessoryRate>(`getAccessoryRate id=${id}`))
    );
  }
  
  getAccessoryRates(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + '/tenant/' + localStorage.getItem('tenant'), {
      params,
      headers: this.headers
    }).pipe(
      tap(accessoryRates => this.log(`fetched accessoryRates`)),
      catchError(this.handleError('getAccessoryRates', []))
    );
  }

  createEnhancedAccesoryRate(payload){
    return this.http.post<any>(this.base_url + '/enhanced/accessory/create', payload, { headers: this.headers }).pipe(
      tap((rate: any) => this.log(`added any`)),
      catchError(this.handleError<any>('addRate', 500))
    );
  }


  getAccessoryRatesByTenantId(id: number): Observable<AccessoryRate[]> {
    return this.http.get<AccessoryRate[]>(this.base_url + '/tenant/' + id, {headers: this.headers}).pipe(
      tap(accessoryRates => this.log(`fetched accessoryRates`)),
      catchError(this.handleError('getAccessoryRates', []))
    );
  }

  getRateHistory(id: number) {
    const url = `${this.base_url}/history/${id}`;
    return this.http.get<AccessoryRate>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched accessoryRate id=${id}`)),
      catchError(this.handleError<AccessoryRate>(`getAccessoryRate id=${id}`))
    );
  }

  /** GET accessoryRate by id. Return `undefined` when id not found */
  getAccessoryRateNo404<Data>(id: number): Observable<AccessoryRate> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<AccessoryRate[]>(url).pipe(
      map(accessoryRates => accessoryRates[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} accessoryRate id=${id}`);
      }),
      catchError(this.handleError<AccessoryRate>(`getAccessoryRate id=${id}`))
    );
  }

  /** GET accessoryRate by id. Will 404 if id not found */
  getAccessoryRate(id: number): Observable<AccessoryRate> {
    const url = `${this.base_url}/${id}/`;
    return this.http.get<AccessoryRate>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched accessoryRate id=${id}`)),
      catchError(this.handleError<AccessoryRate>(`getAccessoryRate id=${id}`))
    );
  }

  /** GET accessoryRate by tenant id. Will 404 if id not found */
  getAccessoryRateByTenant(query?): Observable<AccessoryRate[]> {
    let params = new HttpParams();
    if (query !== undefined){
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const url = `${this.base_url}/tenant/` + localStorage.getItem('tenant');
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched accessoryRate id=`)),
      catchError(this.handleError(`getAccessoryRate `, []))
    );
  }

  /** GET accessoryRate by product id. Will 404 if id not found */
  getAccessoryRateProductCatalogue(id): Observable<any> {
    // const id = localStorage.getItem('tenant');
    const url = `${this.base_url}/tenant/productcatalogue/${id}/`;
    return this.http.get(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched accessoryRates by product catalogue id=${id}`)),
      catchError(this.handleError(`getAccessoryRateByProductCatalogue id=${id}`))
    );
  }

  /* GET accessoryRates whose name contains search term */
  searchAccessoryRates(term: string): Observable<AccessoryRate[]> {
    if (!term.trim()) {
      // if not search term, return empty accessoryRate array.
      return of([]);
    }
    return this.http.get<AccessoryRate[]>(`api/accessoryRates/?name=${term}`).pipe(
      tap(_ => this.log(`found accessoryRates matching "${term}"`)),
      catchError(this.handleError<AccessoryRate[]>('searchAccessoryRates', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new accessoryRate to the server */
  addAccessoryRate(accessoryRate: AccessoryRate): Observable<AccessoryRate> {
    return this.http.post<AccessoryRate>(this.base_url + '/', accessoryRate, { headers: this.headers }).pipe(
      tap((accessoryRate: AccessoryRate) => this.log(`added accessoryRate w/ id=${accessoryRate.id}`)),
      catchError(this.handleError<AccessoryRate>('addAccessoryRate'))
    );
  }

  // addAccessoryRate(accessoryRate: AccessoryRate): Observable<AccessoryRate> {
  //   return this.http.post<AccessoryRate>(this.base_url + '/', accessoryRate, { headers: this.headers }).pipe(
  //     tap((newAccessoryRate: AccessoryRate) => {
  //       this.log(`added accessoryRate w/ id=${newAccessoryRate.tenantId}`);
  //       // patch info
  //       this.editAccessoryRate({ value: accessoryRate.id, id: newAccessoryRate.tenantId, param: 'tenant' }).subscribe(data => {
  //       }, error => console.log(error, 'accessoryRate error'));
  //     }),
  //     catchError(this.handleError<AccessoryRate>('addAccessoryRate'))
  //   );
  // }

  /** POST: add a new accessoryRate to the server */
  addAccessoryRateAdditionalInfo(accessoryRate): Observable<{}> {
    return this.http.post(`${this.base_url}/additionalInfo/${accessoryRate.id}`, accessoryRate.value, { headers: this.headers }).pipe(
      tap((res) => this.log(`added accessoryRate  info `)),
      catchError(this.handleError('addAccessoryRate'))
    );
  }

  /** DELETE: delete the accessoryRate from the server */
  deleteAccessoryRate(accessoryRate: AccessoryRate | number): Observable<AccessoryRate> {
    const id = typeof accessoryRate === 'number' ? accessoryRate : accessoryRate.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<AccessoryRate>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted accessoryRate id=${id}`)),
      catchError(this.handleError<AccessoryRate>('deleteAccessoryRate'))
    );
  }

  /** PUT: update the accessoryRate on the server */
  updateAccessoryRate(accessoryRate: AccessoryRate): Observable<any> {
    return this.http.put(`${this.base_url}/${accessoryRate.id}`, accessoryRate, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated accessoryRate id=${accessoryRate.id}`)),
      catchError(this.handleError<any>('updateAccessoryRate'))
    );
  }

  /** Patch: update the accessoryRate on the server */
  editAccessoryRate(data): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, {headers: this.headers}).pipe(
      tap(_ => this.log(`edit accessoryRate id=${data.id}`)),
      catchError(this.handleError<any>('editAccessoryRate'))
    );
  }

}

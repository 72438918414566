import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {
  ConsignmentAddInventoryProductToConsignment,
  ConsignmentAddInventoryProductToConsignmentSuccess,
  ConsignmentProductActionTypes,
  ConsignmentProductError,
  ConsignmentRemoveInventoryProductFromConsignment,
  ConsignmentRemovePartsFromConsignment,
  ConsignmentRemoveInventoryProductFromConsignmentSuccess,
  GetConsignmentProductByConsignmentIdSuccess,
  UpdateConsignmentProduct,
  GetConsignmentProductByConsignmentId, UpdateConsignmentProductSuccess
} from '@appStore/actions/core-inventory/consignment-product/consignment-product.action';
import {ConsignmentService} from '@appServices/core-inventory/consignment/consignment-service';
import {ConsignmentProductService} from '@appServices/core-inventory/consignment-product/consignment-product.service';
import {InventoryProductService} from '@appServices/core-inventory/inventory-product/inventory-product.service';
import {StockingPointService} from '@appServices/core-inventory/stocking-point/stocking-point.service';
import {ProductCatalogueService} from '@appServices/core-masterdata/product-catalogue/product-catalogue.service';
import {ConsignmentProductAcceptReceiveStatusService} from '@appServices/core-inventory/consignment-product-accept-receive-status/consignment-product-accept-receive-status.service';
import {ToastrService} from 'ngx-toastr';
import {
  ConsignmentProductInventoryProductActionTypes,
  ConsignmentProductNotInStockingPoints,
  ConsignmentProductNotInStockingPointsSuccess,
  ConsignmentProductRemoveStockingPointConsignmentProductsNotBooked,
  ConsignmentProductStockingPointNotBooked,
  ConsignmentProductStockingPointNotBookedSuccess
} from '@appStore/actions/core-inventory/consignment-product/consignment-product-inventory-product.action';


@Injectable()
export class ConsignmentProductEffects {
  
  loadConsignmentProductsByConsignmentId$ = createEffect(() => this.actions$.pipe(
    ofType(ConsignmentProductActionTypes.consignmentProductGetConsignmentProductByConsignmentId),
    mergeMap((action: GetConsignmentProductByConsignmentId) =>
      this.consignmentProductService
        .getConsignmentProductByConsignment(action.payload.id, action.payload.query)
        .pipe(
          map(consignmentProducts => {
            return new GetConsignmentProductByConsignmentIdSuccess(consignmentProducts);
          }),
          catchError(error => of(new ConsignmentProductError(error)))
        )
    )
  ));


  
  getConsignmentProductByNotBookedInStockingPoint$ = createEffect(() => this.actions$.pipe(
    ofType(ConsignmentProductInventoryProductActionTypes.consignmentProductGetStockingPointConsignmentProductsNotBooked),
    switchMap((action: ConsignmentProductStockingPointNotBooked) =>
      this.consignmentProductService
        .getConsignmentProductByNotBookedInStockingPoint(action.payload.id, action.payload.query)
        .pipe(
          map(
            InventoryProducts => {
              if (InventoryProducts !== undefined) {
                return new ConsignmentProductStockingPointNotBookedSuccess(InventoryProducts);
              }
              this.toastr.error('There was an error fetching not booked consignment products!', 'Unknown error');
              return new ConsignmentProductError({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new ConsignmentProductError(error)))
        )
    )
  ));

  
  addInventoryProductToConsignment$ = createEffect(() => this.actions$.pipe(
    ofType(ConsignmentProductActionTypes.consignmentAddInventoryProductToConsignment),
    switchMap((action: ConsignmentAddInventoryProductToConsignment) =>
      this.consignmentService
        .addInventoryProductToConsignment(action.payload, action.payload2)
        .pipe(
          map(
            consignmentProduct => {
              if (consignmentProduct) {
                return new ConsignmentAddInventoryProductToConsignmentSuccess(consignmentProduct);
              } else {
                return new ConsignmentProductError(consignmentProduct);
              }
            }
          ),
          catchError(error => of(new ConsignmentProductError(error)))
        )
    )
  ));


  
  addInventoryProductToConsignmentSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ConsignmentProductActionTypes.consignmentAddInventoryProductToConsignmentSuccess),
    switchMap((action: ConsignmentAddInventoryProductToConsignmentSuccess) => {
      return of(new ConsignmentProductRemoveStockingPointConsignmentProductsNotBooked(action.payload?.id));
    })
  ));

  
  consignmentRemoveInventoryProductFromConsignment$ = createEffect(() => this.actions$.pipe(
    ofType(ConsignmentProductActionTypes.consignmentRemoveInventoryProductFromConsignment),
    switchMap((action: ConsignmentRemoveInventoryProductFromConsignment) =>
      this.consignmentService
        .removeInventoryProductToConsignment(action.consignmentId, action.inventoryProductId)
        .pipe(
          map(
            res => {
              return new ConsignmentRemoveInventoryProductFromConsignmentSuccess(action.consignmentProductId);
            }
          ),
          catchError(error => of(new ConsignmentProductError(error)))
        )
    )
  ));

  consignmentRemovePartsFromConsignment$ = createEffect(() => this.actions$.pipe(
    ofType(ConsignmentProductActionTypes.consignmentRemovePartsFromConsignment),
    switchMap((action: ConsignmentRemovePartsFromConsignment) =>
      this.consignmentService
        .removePartsFromConsignment(action.consignmentPartId)
        .pipe(
          map(
            res => {
              return new ConsignmentRemoveInventoryProductFromConsignmentSuccess(action.consignmentPartId);
            }
          ),
          catchError(error => of(new ConsignmentProductError(error)))
        )
    )
  ));

  consignmentProductUpdateConsignmentProduct$ = createEffect(() => this.actions$.pipe(
    ofType(ConsignmentProductActionTypes.consignmentProductUpdateConsignmentProduct),
    mergeMap((action: UpdateConsignmentProduct) =>
      this.consignmentProductService
        .updateConsignmentProduct(action.consignmentProductId, action.acceptReceiveStatus)
        .pipe(
          map(
            consignmentProduct => {
              consignmentProduct.consignmentProductAcceptReceiveStatusParentId = action.acceptReceiveStatus.id;

              if (action.acceptReceiveStatus.status) {
                consignmentProduct.consignmentProductAcceptReceiveStatusStatus = 'ACCEPTED';
              } else {
                consignmentProduct.consignmentProductAcceptReceiveStatusStatus = 'REJECTED';
              }
              consignmentProduct.consignmentProductAcceptReceiveStatusAcceptReceiveStatus = action.acceptReceiveStatus.acceptReceiveStatus;
              return new UpdateConsignmentProductSuccess(consignmentProduct);
            }
          ),
          catchError(error => of(new ConsignmentProductError(error)))
        )
    ),
  ));


  
  getConsignmentProductNotInStockingPoint$ = createEffect(() => this.actions$.pipe(
    ofType(ConsignmentProductInventoryProductActionTypes.consignmentProductNotInStockingPoints),
    switchMap((action: ConsignmentProductNotInStockingPoints) =>
      this.consignmentProductService
        .getConsignmentProductNotInStockingPoints()
        .pipe(
          map(
            InventoryProducts => {
              if (InventoryProducts !== undefined) {
                return new ConsignmentProductNotInStockingPointsSuccess(InventoryProducts);
              }
              this.toastr.error('There was an error fetching inventory products not in stocking point!', 'Unknown error');
              return new ConsignmentProductError({type: 500, message: 'Internal error'});
            }
          ),
          catchError(error => of(new ConsignmentProductError(error)))
        )
    )
  ));

  constructor(
    private actions$: Actions,
    private consignmentService: ConsignmentService,
    private stockingPointService: StockingPointService,
    private consignmentProductService: ConsignmentProductService,
    private inventoryProductService: InventoryProductService,
    private productCatalogueService: ProductCatalogueService,
    private consignmentProductAcceptReceiveStatusService: ConsignmentProductAcceptReceiveStatusService,
    private toastr: ToastrService
  ) {
  }


}




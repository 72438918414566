import { ChangeDetectorRef, Component, inject, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { EscalationImagesService } from "@appServices/core-crm/installation-pictures/escallation-pictures.service";
import TimeZone from "app/util/timezone";

@Component({
  selector: "kyc-image-and-attachments",
  template: `
    <div *ngIf="image">
      <label class="label_title">{{ "Profile Photo" | translate }}</label>
      <div>
        <img [src]="image" alt="Profile Photo" width="70px" />
      </div>
    </div>
    
    <div *ngIf="attachmentsExists">
      <div *ngIf="!imageDetailCheck && formNotValid" class="notification error">
        {{ "Please confirm all attachment details" | translate }}
      </div>
      <h4 class="m-t-20 m-b-10">{{ "Attachments Details" | translate }}</h4>
      <div class="box">
        <div class="gallery-image">
          <div class="img-box" *ngFor="let file of attachments; let i = index">
            <img [src]="file.blob" alt="{{ file.fileType }}" />
            <div class="transparent-box">
              <div class="caption-check">
                <input
                  type="checkbox"
                  *ngIf="statusName !== 'BLACKLISTED'"
                  class="m-r-10"
                  [(ngModel)]="file.checked"
                  name="imageDetailCheck"
                />
              </div>
              <a [href]="file.blob" (click)="openBlobInNewTab(file.blob)" style="cursor: pointer">
                <div class="caption">
                  <p class="text-white">{{ file.fileType }}</p>
                  <p class="opacity-low">{{ formatDate(file.createdAt) }}</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="images.length > 0; else noImages" class="image-container">
      <h4 class="m-t-20 m-b-10">Installation Pictures</h4>
      <div class="image-grid">
        <div *ngFor="let img of images; trackBy: trackByFn" class="image-item">
          <img [src]="img.value" alt="Installation Image" />
        </div>
      </div>
    </div>

    <ng-template #noImages>
      <p class="no-images-text" style="font-weight: bold;">No Installation pictures are available.</p>
    </ng-template>
  `,
  encapsulation: ViewEncapsulation.None
})
export class NKycImageAndAttachmentsComponent implements OnInit, OnChanges {
  private escalationService = inject(EscalationImagesService);
  @Input() attachmentsExists: any;
  @Input() imageDetailCheck: any;
  @Input() formNotValid: any;
  @Input() image: any;
  @Input() attachments: any;
  @Input() statusName: any;
  @Input() contractDetails: any;
  
  images: { value: string }[] = [];
  isImagesLoading = true;
  formatDate = TimeZone.getFormatedTimezoneFromDate;

  constructor(private ref: ChangeDetectorRef) {}

  ngOnInit() {}

  openBlobInNewTab(blob: string) {
    const win = window.open();
    win.document.write('<img src="' + blob + '">');
  }

  ngOnChanges(changes: SimpleChanges): void {    
    if (changes.contractDetails && changes.contractDetails.currentValue) {
      this.contractDetails = changes.contractDetails.currentValue;
      if (this.contractDetails?.installationData?.installationAttachment) {
        const images = this.contractDetails?.installationData?.installationAttachment;
        if (images.length > 0) { 
          for (const image of images) {
            this.generateUrl(image.attachmentId);
          }
       }
      }
    }
  }

  generateUrl(id: number): void {
    this.escalationService.getInstallationPictures(id)
      .subscribe(imageBlob => {
        if (imageBlob && imageBlob.size > 0) {
          const reader = new FileReader();
          reader.readAsDataURL(imageBlob);

          reader.onload = () => { 
            this.images = [...this.images, { value: reader.result as string }];
            this.isImagesLoading = false; 
            this.ref.detectChanges();
          };
        } else {
          this.isImagesLoading = false; 
        }
      });
  }

  trackByFn(index: number, item: any): any {
    return item.value;
  }
}

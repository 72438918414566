<div *ngIf="!isLoading; else loadingTemplate" style="width: 100%;">
  <div *ngIf="mapUrl && installationsUrl" style="display: flex; justify-content: space-between; padding-bottom: 5px;">
    <span class="sales-label">Sales Location</span>
    <div class="margin-class">Installation Location</div>
  </div>

  <div style="display: flex; width: 100%; height: 450px; gap: 10px;">
    <div *ngIf="mapUrl" [style.width]="installationsUrl ? '50%' : '100%'" style="height: 100%;">
      <iframe
        [src]="mapUrl"
        width="100%"
        height="100%"
        style="border: 0;"
        allowfullscreen
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    <div *ngIf="installationsUrl" [style.width]="mapUrl ? '50%' : '100%'" style="height: 100%;">
      <iframe
        [src]="installationsUrl"
        width="100%"
        height="100%"
        style="border: 0;"
        allowfullscreen
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <p>Loading...</p>
</ng-template>

import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {ConsignmentProduct} from '@appModels/core-inventory/consignment-product/consignment-product';
import {
  ConsignmentProductActions,
  ConsignmentProductActionTypes
} from '@appStore/actions/core-inventory/consignment-product/consignment-product.action';
import * as _ from 'lodash';

export interface State extends EntityState<ConsignmentProduct> {
  loaded: boolean;
  loading: boolean;
  error: any;
  selectedConsignmentProductId: number;
  searchConsignmentProducts: ConsignmentProduct[];
  consignmentConsignmentProducts: ConsignmentProduct[];
  addedConsignmentProduct: ConsignmentProduct;
  paging: any;
}

export const adapter: EntityAdapter<ConsignmentProduct> = createEntityAdapter<ConsignmentProduct>();

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  selectedConsignmentProductId: null,
  error: null,
  searchTerm: '',
  searchConsignmentProducts: null,
  consignmentConsignmentProducts: null,
  addedConsignmentProduct: null,
  paging: null
});

export function reducer(state = initialState, action: ConsignmentProductActions): State {
  switch (action.type) {
    case ConsignmentProductActionTypes.consignmentProductGetConsignmentProducts:
    case ConsignmentProductActionTypes.consignmentProductAddConsignmentProduct:
    case ConsignmentProductActionTypes.consignmentProductAddAdditionalConsignmentProductInfo:
    case ConsignmentProductActionTypes.consignmentProductDeleteConsignmentProduct:
    case ConsignmentProductActionTypes.consignmentProductUpdateConsignmentProduct:
    case ConsignmentProductActionTypes.consignmentProductSearchConsignmentProducts:
    case ConsignmentProductActionTypes.consignmentProductGetConsignmentProductById:
    case ConsignmentProductActionTypes.consignmentProductGetConsignmentProductByConsignmentId:
    case ConsignmentProductActionTypes.consignmentAddInventoryProductToConsignment:
    case ConsignmentProductActionTypes.consignmentRemoveInventoryProductFromConsignment:
    case ConsignmentProductActionTypes.consignmentRemovePartsFromConsignment:

      return {
        ...state,
        loading: true
      };

    case ConsignmentProductActionTypes.consignmentProductGetConsignmentProductsSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    case ConsignmentProductActionTypes.consignmentProductGetConsignmentProductByIdSuccess:
      return {...state, selectedConsignmentProductId: action.payload.id, loading: false};

    case ConsignmentProductActionTypes.consignmentProductAddConsignmentProductSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        selectedConsignmentProductId: action.payload.id,
        loading: false,
        loaded: true
      });

    case ConsignmentProductActionTypes.consignmentProductUpdateConsignmentProductSuccess: {
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload
        },
        {
          ...state,
          loading: false,
          loaded: true
        }
      );
    }

    case ConsignmentProductActionTypes.consignmentProductDeleteConsignmentProductSuccess: {
      return adapter.removeOne(action.payload.id, {
        ...state,
        loading: false,
        loaded: true
      });
    }

    case ConsignmentProductActionTypes.consignmentProductGetConsignmentProductByConsignmentIdSuccess: {
      return adapter.setAll(action.payload.content, {
        ...state,
        loading: false,
        loaded: true,
        paging: _.omit(action.payload, ['content'])
      });
    }

    case ConsignmentProductActionTypes.consignmentProductSearchConsignmentProductsSuccess:
      return {
        ...state,
        searchConsignmentProducts: action.payload,
        loading: false
      };

    case ConsignmentProductActionTypes.consignmentProductSearchConsignmentProductsReset:
      return {
        ...state,
        searchConsignmentProducts: null
      };


    case ConsignmentProductActionTypes.consignmentAddInventoryProductToConsignmentSuccess:
      return adapter.addOne(action.payload,
        {
          ...state,
          addedConsignmentProduct: action.payload,
          loading: false,
          loaded: true
        });

    case ConsignmentProductActionTypes.consignmentProductError:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };


    case  ConsignmentProductActionTypes.consignmentRemoveInventoryProductFromConsignmentSuccess:
      return adapter.removeOne(action.payload, {
        ...state,
        loading: false,
        loaded: true
      });

    default:
      return state;
  }

}


export const consignmentProductEntitySelectors = adapter.getSelectors();

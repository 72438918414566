import {ChangeDetectorRef, Component, Input, TemplateRef, ViewChild} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {ActivatedRoute, Router} from "@angular/router";
import {TicketService} from "@appServices/core-aftersales/ticket/ticket.service";
import {TicketingService} from "@appServices/core-ticketing/customer/ticketing-service";
import {TicketDashboardService} from "@appServices/core-ticketing/ticket-dashboard-service";
import {TicketTypeStatusBreakdown} from "@appModels/ticketing/reporting/ticket-type-status-breakdown";

@Component({
  moduleId: module.id,
  templateUrl: './ticket-dashboard.component.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['../../tailwind.css']
})
export class TicketDashboardComponent {
  constructor(
    private route:ActivatedRoute,
    private router: Router,
    private dashboardService: TicketDashboardService


  ) {
    dashboardService.getTypeStatusBreakdown().subscribe(x => {
      this.ticketTypeStatusBreakdown = x;
    })
  }

  ticketTypeStatusBreakdown: TicketTypeStatusBreakdown = {};


}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
  ViewEncapsulation,
  signal,
} from "@angular/core";

import { ContractService } from "@appServices/core-accounting/contract/contract.service";
import { RateService } from "@appServices/core-accounting/rate/rate.service";
import { MaktabaService } from "@appServices/core-crm/maktaba/maktaba.service";
import { ContractVerificationService } from "@appServices/core-crm/contract-verification/contract-verification.service";
import { CookstoveAdditionalInfoService } from "@appServices/core-crm/cookstove-additional-info/cookstove-additional-info.service";
import { CustomerService } from "@appServices/core-crm/customer/customer.service";
import { IdentificationTypeService } from "@appServices/core-crm/identification-type/identification-type.service";
import { TenantSalesStaffService } from "@appServices/core-fieldforce/sales-staff/tenant-sales-staff.service";
import { ContractVerificationReasonService } from "@appServices/core-masterdata/contract-verification-reason/contract-verification-reason.service";
import { ProductCatalogueService } from "@appServices/core-masterdata/product-catalogue/product-catalogue.service";
import { TenantService } from "@appServices/core-masterdata/tenant/tenant.service";
import { SmileIdService } from "@appServices/core-smile-identity/smile-id/smile-id.service";
import { PermissionResolver } from "@appServices/permission-resolver/permission-resolver.service";
import { TenantPreferenceResolver } from "@appServices/tenant-preference-resolver/tenant-preference-resolver.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import Helpers from 'app/util/helpers';
import { Router } from "@angular/router";
import { CustomerRateDetailService } from "@appServices/core-accounting/rate/rate.details.service";
import { KycPaymentDetails } from "@appModels/core-crm/kyc-rate/kyc-rate.interface";

export enum KYC_COMPONENT_MODE {
  READ_ONLY = "READ_ONLY",
  EDIT = "EDIT",
}
export interface Question {
  id: number;
  description: string;
  question: string;
}
export interface Score {
  scores: number;
}
@Component({
  selector: "app-kyc-shared-modal",
  templateUrl: "./kyc-shared-modal.component.html",
  styleUrls: ["./kyc-shared-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class KYCSharedModalComponent implements OnInit {
  kycComponentModeEnum = KYC_COMPONENT_MODE;
  @Input() componentMode: KYC_COMPONENT_MODE = KYC_COMPONENT_MODE.EDIT;


  loadingDetails: boolean = false;
  @Input() awaitingEscalation: any = {};
  @Input() reasonObject?: string[];
  hasAccessory: boolean;
  idVerificationPassed: string;
  secondaryContactTelephone: any;
  countryCodeNumeric: any;
  contracts: any;
  upsellCustomer: boolean;
  contractVerificationContracts: any;
  totalPaid: any;
  accountNumber: any;
  agent: any;
  dealer: any;
  rate: any;
  deposit: any;
  product: any;
  primaryProductSmileIdRequired: string;
  cookStoveAccessoryInfo: any;
  additionalComments: any;
  additionalKycInfo: any;
  rejectionInfo: any;
  blacklistInfo: any;
  callBackInfo: string = '';
  customerIsASalesAgentWithId: any;
  preferredOption: any;
  preferredCommunicationOption: any;
  assignedUser: any;
  assignedUserId: any;
  statusName: string;
  rateIds: number[];
  rateDetails: KycPaymentDetails;

  accessoriesDetails = [];
  mpesaStatementData: any;
  mpesaStatementSummary = [] as any;
  cookstoveDetail = [];
  callScoreCardQuestionsAnswer = {};
  callScoreCardAnswers = [];
  image: string | ArrayBuffer;
  showMessage: boolean;
  salesAgentPhone: any;
  salesAgent: string;
  attachmentsExists: boolean;
  attachments: any;
  identificationType: any;
  resubmissionCount: any;
  contractDetails: any = {};
  loading: boolean;
  awaitingReviewCustomer: any;
  awaitingReviewCustomerContent: any[];
  perPage = 10;
  kycType: string;
  sortId = "ID,desc";
  status = "KYC_PENDING";
  preferredOptionTime: any;
  otherPreferredOption: null;
  loadingApprove: boolean;
  isFormValid: any;
  formNotValid: boolean;
  productDetailCheck: any;
  mpesaStatementCheck: any;
  sepDetailCheck: any;
  customerDetailCheck: any;
  crbDetailCheck: boolean = false;

  disableApprove: boolean;
  preferredOptionCheck: boolean;
  preferredTimeCheck: boolean;
  resubmissionCheck: boolean;
  loadingResubmit: boolean;
  disableResubmit: boolean;
  rejectionCheck: boolean;
  callbackCheck: boolean;
  loadingReject: boolean;
  disableReject: boolean;
  loadingBlacklist: boolean;
  disableBlacklist: boolean;
  blacklistCheck: boolean;
  confirmClicked = false;
  cancelClicked = false;
  location: any;
  startQuestion = false;
  showTotalScore: boolean;
  callScoreCardQuestions = [];
  currentQuestionIndex = 0;
  isAllScoreAnswered: boolean;

  customerInfoGenerals = null;
  uniqueIdentification = '';

  popoverTitle = "Approve";
  popoverMessage = "Are you sure you want to approve?";
  imageDetailCheck = false;
  user = JSON.parse(localStorage.getItem("user")).id;
  tenantId = null;

  @Input() blacklistReasons = [];
  @Input() rejectedReasons = [];
  scoreSum: number;
  showComponent: boolean;
  leadSmileIdProduct: any;
  constructor(
    private ref: ChangeDetectorRef,
    private toastr: ToastrService,
    private customerService: CustomerService,
    private productCatalogueService: ProductCatalogueService,
    private rateService: RateService,
    private maktabaService: MaktabaService,
    private smileIdService: SmileIdService,
    private cookstoveAdditionalInfoService: CookstoveAdditionalInfoService,
    private tenantSalesStaffService: TenantSalesStaffService,
    private contractService: ContractService,
    private identificationTypeService: IdentificationTypeService,
    private contractVerificationService: ContractVerificationService,
    private rateDetail : CustomerRateDetailService,
    private permissionResolverService: PermissionResolver,
    private tenantService: TenantService,
    private tenantPreferenceResolver: TenantPreferenceResolver,
    public modalRef: BsModalRef,
    private router: Router,
    private contractVerificationReasonService: ContractVerificationReasonService
  ) { }

  closeModal() {
    this.modalRef.hide();
  }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.checkIfMobileView();
  }
  checkIfMobileView() {
    this.showComponent = window.innerWidth < 1396;
  }
  ngOnInit() {
    this.checkRoute();
    this.getBlacklistReason();
    this.getRejectedReason();
    this.tenantId = +localStorage.getItem("tenant");
    this.accessoriesDetails = [];
    this.image = null;
    this.location = this.awaitingEscalation.location;
    this.statusName = this.awaitingEscalation.statusName;
    this.getCustomer(this.awaitingEscalation.id);
    this.sepDetailCheck = false;
    this.customerDetailCheck = false;
    this.productDetailCheck = false;
    this.formNotValid = false;
    this.preferredOptionCheck = false;
    this.preferredTimeCheck = false;
    this.statusName === "BLACKLISTED"
      ? (this.blacklistCheck = true)
      : (this.blacklistCheck = false);
    (this.statusName === "LEAD" || this.statusName === "HOT_LEAD")
      ? (this.rejectionCheck = true)
      : (this.rejectionCheck = false);
    this.statusName === "KYC_AWAITING_MORE_INFORMATION"
      ? (this.resubmissionCheck = true)
      : (this.resubmissionCheck = false);
    this.startQuestion = false;
    this.showTotalScore = false;
    this.currentQuestionIndex = 0;
    this.checkIfMobileView();
  }

  getLeadSmileIdProduct(customerId) {
    this.smileIdService.getLeadSmileIdProduct(customerId).subscribe(data => {
      this.leadSmileIdProduct = data
    })
  }

  checkRoute() {
    const currentUrl = this.router.url;
    return currentUrl.includes('cco-leads');
  }


getLatestMpesaLogMessage() {
  if(this.mpesaStatementData && this.mpesaStatementData.logs?.length>0){
    return this.mpesaStatementData!.logs[0].message;
  }
}

/*
 * Todo @JOY: For housekeeping
 *
 * This method is somewhat bulky and follows an anti-pattern by mixing promises and observables.
 * Additionally, it lacks parallelism, which is essential since some variables are dependencies for child components.
 * The function should be optimized for better readability and efficiency.
 */

  discountTenantFlag:any = null;
  discount:any = null;


  getCustomer(id: any) {
    this.hasAccessory = false;
    this.loadingDetails = true;
    this.customerService.get(id).subscribe(
      async (data) => {
        if (data) {
          this.awaitingEscalation = data;
          this.uniqueIdentification = (data?.identificationValue ?? '');

          if (data.idVerificationPassed === true) {
            this.idVerificationPassed = "Pass";
          } else if (data.idVerificationPassed === false) {
            this.idVerificationPassed = "Fail";
          }
          if (`${data.secondaryContactTelephone}`.length <= 9) {
            this.secondaryContactTelephone =
              this.countryCodeNumeric + data.secondaryContactTelephone;
          } else {
            this.secondaryContactTelephone = data.secondaryContactTelephone;
          }
          this.contracts = data.contracts;
          if (this.contracts.length > 0) {
            this.upsellCustomer = true;
          }
          this.contractVerificationContracts =
            data.contractVerificationContracts[0];
          this.totalPaid =
            data.contractVerificationContracts[0]?.paymentTransactions.reduce(
              (sum, x) => sum + x.paymentRequest?.amount,
              0
            );
          let verificationProduct =
            data.contractVerificationContracts[0]?.contractVerificationContractProducts?.filter(
              (x) => !x.addendum
            )[0] ?? null;
          let verificationAccessories =
            data.contractVerificationContracts[0]?.contractVerificationContractProducts?.filter(
              (x) => x.addendum
            );
          let accessorySale =
            data.contractVerificationContracts[0].accessorySale;

          if (verificationProduct) {
            let productRate = await this.rateService
              .get(data.contractVerificationContracts[0]?.rateId)
              .toPromise();
            let productCatalogue = await this.productCatalogueService
              .get(verificationProduct.tenantProductCatalogueId)
              .toPromise();
            let globalProduct = await this.smileIdService
              .getGlobalProductByTenantProductCatalogueOnKyc(
                verificationProduct.tenantProductCatalogueId
              )
              .toPromise();

            this.accountNumber = verificationProduct.accountNumber;
            this.agent = verificationProduct.salesAgentId;
            this.dealer = verificationProduct.dealerId;
            this.rateIds = productRate.rateTenantProductCatalogues.map(item => item.rateId);
            this.rate = productRate.name;
            this.deposit = productRate.rateFinancedDefinitions?.depositAmount;
            this.product = productCatalogue.productDisplayName;
            this.primaryProductSmileIdRequired = globalProduct.smileIdRequired
              ? "YES"
              : "NO";
          }

          if (this.tenantId == '105' || this.tenantId == '1001') {
            this.mpesaStatementData = await this.maktabaService.getMpesaStatement(Helpers.formatKenyanPhoneNumber(data.telephone1)).toPromise();
            this.mpesaStatementSummary = this.mpesaStatementData.data;
            const totalPaidIn = this.mpesaStatementSummary.reduce((sum, current) => sum + parseFloat(current.paidIn), 0);
            const totalPaidOut = this.mpesaStatementSummary.reduce((sum, current) => sum + parseFloat(current.paidOut), 0);

            this.mpesaStatementSummary.push({
              transactionType: 'Total',
              paidIn: totalPaidIn,
              paidOut: totalPaidOut
            });
          }
          if (accessorySale) {
            let productRate = await this.rateService
              .get(data.contractVerificationContracts[0]?.rateId)
              .toPromise();
            let productCatalogue = await this.productCatalogueService
              .get(
                productRate?.rateTenantProductCatalogues[0]
                  ?.tenantProductCatalogueId
              )
              .toPromise();
            this.rate = productRate.name;
            this.product = productCatalogue.productDisplayName;
            this.deposit = productRate.rateFinancedDefinitions?.depositAmount;
            this.cookStoveAccessoryInfo = accessorySale.callLogAccessorySale;
            this.getCookstoveAdditionalInfo(data.id);
          }

          if (verificationAccessories.length > 0) {
            this.hasAccessory = true;

            for (const accessory of verificationAccessories) {
              let accessoryRate = await this.rateService
                .get(accessory.secondaryProductRateId)
                .toPromise();
              let accessoryCatalogue = await this.productCatalogueService
                .get(accessory.tenantProductCatalogueId)
                .toPromise();
              let globalProduct = await this.smileIdService
                .getGlobalProductByTenantProductCatalogueOnKyc(
                  verificationProduct.tenantProductCatalogueId
                )
                .toPromise();

              this.accessoriesDetails.push({
                name: accessoryCatalogue?.productDisplayName,
                deposit: accessoryRate.rateFinancedDefinitions?.depositAmount,
                perUnitAmount:
                  accessoryRate.rateFinancedDefinitions?.perUnitAmount,
                rateName: accessoryRate.name,
                accountNumber: accessory.accountNumber,
                agent: accessory.salesAgentId,
                dealer: accessory.dealerId,
                length: this.calculateContractLength(accessoryRate),
                smileIdRequired: globalProduct.smileIdRequired ? "YES" : "NO",
              });
            }
          }


          if (data.contractVerificationContracts?.length > 0) {
            this.additionalComments =
              data.contractVerificationContracts[0]?.additionalComments;
            this.additionalKycInfo =
              data.contractVerificationContracts[0]?.additionalKycInfo;
            this.rejectionInfo =
              data.contractVerificationContracts[0]?.additionalKycInfo;
            this.blacklistInfo =
              data.contractVerificationContracts[0]?.additionalKycInfo;
          }

          this.customerIsASalesAgentWithId = data.salesStaffId;

          let communication = data.preferredMeansOfCommunication;
          if (communication !== null) {
            communication == "WhatsApp" ||
              communication == "Phone Call" ||
              communication == "SMS"
              ? (this.preferredOption = communication)
              : ((this.preferredOption = "Other"),
                (this.preferredOption = this.preferredCommunicationOption =
                  communication));
          } else {
            this.preferredOption = null;
          }
          this.preferredOption = data.preferredTimeOfContact;

          if (data.contractVerificationContracts[0].length > 1) {
            this.assignedUser =
              data.contractVerificationContracts[1]?.assignedUser;
            this.assignedUserId =
              data.contractVerificationContracts[1]?.assignedUser?.id;
          } else {
            this.assignedUser =
              data.contractVerificationContracts[0]?.assignedUser;
            this.assignedUserId =
              data.contractVerificationContracts[0]?.assignedUser?.id;
          }
          if (
            this.assignedUser == null &&
            (this.statusName === "KYC_PENDING" ||
              this.statusName === "KYC_AWAITING_MORE_INFORMATION" ||
              this.statusName === "REQUESTED_CALLBACK" ||
              this.statusName == "KYC_ACCESSORY_PENDING") &&
            this.can("can-approve-awaiting-more-info-customers")
          ) {
            this.assignVerification(this.awaitingEscalation);
          }

          setTimeout(() => {
            if (data && data.customerProfileImage) {
              this.showImage(data.id);
            }
            this.getCustomerAttachments(data.id);
            this.getIdentification(data?.identificationTypeId);
            this.getSubmissionCount(data.contractVerificationContracts[0]?.id);
            this.getContractDetails(data.contractVerificationContracts[0]?.id);
            this.getRateDetails(this.rateIds);
            this.getLeadSmileIdProduct(data.id)

            let accessorySale =
              data.contractVerificationContracts[0]?.accessorySale;
            let salesAgentId =
              data.contractVerificationContracts[0]
                ?.contractVerificationContractProducts[0]?.salesAgentId;
            let cookStoveSalesAgentId =
              data.contractVerificationContracts[0]?.callLogAccessorySale
                ?.salesStaffId;

            this.getSalesAgent(
              accessorySale ? cookStoveSalesAgentId : salesAgentId
            );
          }, 500);
          this.customerInfoGenerals = data.customerInfoGenerals;
          this.ref.detectChanges();
          this.loadingDetails = false;
        }
      },
      (err) => (this.loadingDetails = false)
    );
  }
  can(action) {
    return this.permissionResolverService.setModuleName("CRM").can(action);
  }
  
  getContractDetails(id) {
    this.contractVerificationService
      .getContractDetails(id)
      .subscribe((data) => {
        if (data) this.contractDetails = data;
        else this.contractDetails = {};
      });
  }

  getSubmissionCount(id) {
    this.contractService.getSubmissionCount(id).subscribe((data) => {
      this.resubmissionCount = data;
    });
  }
  getIdentification(id) {
    this.identificationTypeService.get(id).subscribe((data) => {
      this.identificationType = data.name;
    });
  }
  downloadPhoto(data) {
    return this.customerService
      .downloadCustomerDocAttachment(data.id)
      .subscribe((blob: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          data.blob = reader.result;
          this.ref.detectChanges();
        };
      });
  }


  getCustomerAttachments(id) {
    this.customerService.getCustomerDocAttachment(id).subscribe(
      (data) => {
        if (data) {
          this.attachmentsExists = true;
          this.attachments = data.map((d) => ({ ...d, checked: false }));
          if (this.attachments?.length > 0) {
            this.attachments.forEach((attachment) => {
              setTimeout(() => {
                this.downloadPhoto(attachment);
              }, 300);
            });
            this.ref.detectChanges();
          }
        }
        if (data.length === 0) {
          this.attachmentsExists = false;
        }
      },
      (error) => {
        console.log(error, "error......");
      }
    );

    this.tenantService
      .getTenantPreference(+localStorage.getItem("tenant"))
      .subscribe((value) => {
        const tenantPreferenceResolver = this.tenantPreferenceResolver
          .setTenantPreference(value)
          .groupMainPreference();
        if (
          this.tenantPreferenceResolver.getPreferenceValueAsBoolean(
            "CUSTOMER_DIGITAL_SIGNATURE_REQUIRED"
          )
        ) {
          if (
            this.reasonObject?.indexOf(
              "Customer digital signature is missing"
            ) === -1
          ) {
            this.reasonObject.push("Customer digital signature is missing");
          }
          this.handleSignatureImage(id);
        }
      });
  }
  handleSignatureImage(id) {
    this.customerService.getSignatureAttachment(id).subscribe((res) => {
      // Attachment ID in this response is customer ID since signatures are
      // stored on customer table and not in document attachments
      if (res.fileStorageUrl !== null) {
        res = { ...res, checked: false };
        this.downloadSignature(res);
      }
    });
  }
  downloadSignature(data) {
    return this.customerService
      .downloadSignature(data.id)
      .subscribe((blob: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          data.blob = reader.result;
          this.attachments.push(data);
          this.ref.detectChanges();
        };
      });
  }
  getSalesAgent(id) {
    if (id != 0) {
      this.tenantSalesStaffService.getSalesStaff(id).subscribe((data) => {
        this.salesAgent = data?.firstName + " " + data?.lastName;
        this.salesAgentPhone = data?.phoneNumber;
        if (`${data?.phoneNumber}`.length <= 9) {
          this.salesAgentPhone = this.countryCodeNumeric + data?.phoneNumber;
        } else {
          this.salesAgentPhone = data?.phoneNumber;
        }
        // this.agent = data?.dealerId;
      });
    }
  }

  getRateDetails(rateIds:number[])
  {
  
    this.rateDetail
         .getRateByCustomer(rateIds)
         .subscribe((data) => {
           this.rateDetails = data;
         })
  }

  getBlacklistReason() {
    this.contractVerificationReasonService
      .getBlacklistedReasons()
      .subscribe((data) => {
        this.blacklistReasons = data;
      });
  }
  getRejectedReason() {
    this.contractVerificationReasonService
      .getRejectedReasons()
      .subscribe((data) => {
        this.rejectedReasons = data;
      });
  }
  assignVerification(awaitingEscalation) {
    this.contractService
      .assignContractVerification(
        awaitingEscalation.contractVerificationContracts[0].id
      )
      .subscribe((data) => {
        if (data) {
          this.showMessage = false;
        } else {
          this.showMessage = true;
        }
      });
  }
  showImage(data) {
    this.customerService.downloadCustomerPhoto(data).subscribe((blob: any) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.image = reader.result;
        this.ref.detectChanges();
      };
    });
  }
  getCookstoveAdditionalInfo(customerId) {
    this.cookstoveAdditionalInfoService
      .getCookstoveAdditionalInfo(customerId)
      .subscribe((data) => {
        this.cookstoveDetail = data;
      });
  }
  calculateContractLength(accessoryRate) {
    let result =
      accessoryRate.rateFinancedDefinitions?.financedAmount /
      accessoryRate.rateFinancedDefinitions?.perUnitAmount;

    switch (accessoryRate.rateFinancedDefinitions?.timeIntervalVariableId) {
      case 2:
        result *= 7;
        break;
      case 3:
        result *= 30;
        break;
    }

    return Math.ceil(result);
  }

  resetQuestionAnswers() {
    Object.keys(this.callScoreCardQuestionsAnswer).forEach((questionOrder) => {
      this.callScoreCardQuestionsAnswer[questionOrder] = {
        ...this.callScoreCardQuestionsAnswer[questionOrder],
        score: 0,
        isAnswered: false,
        customerCallScriptQuestionId: null,
      };
    });
  }
  changeTime() {
    this.customerService
      .addTime(this.awaitingEscalation.id, {
        preferredOption: this.preferredOptionTime,
      })
      .subscribe((data) =>
        this.toastr.success("Updated preferred time of contract successfully!")
      );
  }
  getStatusForFilter() {
    return this.kycType === "NEW_REGISTRATION"
      ? this.status
      : this.status === "KYC_PENDING"
        ? "KYC_ACCESSORY_PENDING"
        : this.status;
  }
  unassignUser(awaitingEscalation) {
    this.contractService
      .unassignContractVerification(
        awaitingEscalation.contractVerificationContracts[0].id,
        true
      )
      .subscribe((data) => {
        this.getAwaitingReviewCustomers({
          page: 0,
          size: this.perPage,
          customerStatus: this.getStatusForFilter(),
          isAccessorySale: this.kycType == "COOKSTOVE_ACCESSORY",
          sort: this.sortId,
        });
      });
  }

  unassignVerification(awaitingEscalation, statusName) {
    if (
      awaitingEscalation &&
      awaitingEscalation.contractVerificationContracts &&
      awaitingEscalation.contractVerificationContracts.length > 0
    ) {
      if (
        statusName !== "BLACKLISTED" &&
        statusName !== "LEAD" &&
        statusName !== "HOT_LEAD" &&
        statusName !== "KYC_AWAITING_MORE_INFORMATION" &&
        this.can("can-approve-awaiting-more-info-customers")
      ) {
        this.contractService
          .unassignContractVerification(
            awaitingEscalation.contractVerificationContracts[0].id,
            false
          )
          .subscribe((data) => {
            this.getAwaitingReviewCustomers({
              page: 0,
              size: this.perPage,
              customerStatus: this.getStatusForFilter(),
              isAccessorySale: this.kycType == "COOKSTOVE_ACCESSORY",
              sort: this.sortId,
            });
          });
      } else {
        this.closeModal();
      }
    }
  }

  getAwaitingReviewCustomers(query) {
    this.loading = true;

    this.customerService.getCustomers(query).subscribe(
      (data) => {
        if (data && data.content) {
          this.loading = false;
          this.awaitingReviewCustomer = data;
          this.awaitingReviewCustomerContent = [...data.content];
          this.ref.detectChanges();
        }
      },
      (err) => (this.loading = false)
    );
  }

  changeCommunication() {
    if (this.preferredOption !== "Other") {
      this.preferredCommunicationOption = this.preferredOption;
      this.addCommission();
    }
    if (
      this.preferredOption == "Other" &&
      this.otherPreferredOption !== null &&
      this.otherPreferredOption !== ""
    ) {
      this.preferredCommunicationOption = this.otherPreferredOption;
      setTimeout(() => {
        this.addCommission();
      }, 500);
    }
  }

  addCommission() {
    this.customerService
      .addCommunication(this.awaitingEscalation.id, {
        preferredOption: this.preferredCommunicationOption,
      })
      .subscribe((data) =>
        this.toastr.success(
          "Updated preferred means of communication successfully!"
        )
      );
  }
  onAllScoreSaved(): void {
    this.isAllScoreAnswered = true;
  }

  checkFormValidity() {
    if (
      this.sepDetailCheck &&
      this.customerDetailCheck &&
      this.productDetailCheck &&
      (this.checkRoute() ? this.crbDetailCheck : true) &&
      this.preferredCommunicationOption !== null &&
      this.preferredOptionTime !== null
    ) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
  }

  private validatePreference() {
    if (
      this.preferredCommunicationOption == "" ||
      this.preferredCommunicationOption == null
    ) {
      this.preferredOptionCheck = true;
    }
    if (this.preferredOptionTime == "" || this.preferredOptionTime == null) {
      this.preferredTimeCheck = true;
    }
  }
  approveVerification(awaitingEscalation) {
    this.validatePreference();
    this.checkFormValidity();
    if (
      !this.sepDetailCheck &&
      !this.customerDetailCheck &&
      !this.productDetailCheck &&
      ((this.attachmentsExists &&
        !this.attachments.reduce((p, n) => p && n.checked, true)) ||
        !this.attachmentsExists)
    ) {
      this.formNotValid = true;
    }
    if (
      this.isFormValid &&
      ((this.attachmentsExists &&
        this.attachments.reduce((p, n) => p && n.checked, true)) ||
        !this.attachmentsExists)
    ) {
      this.loadingApprove = true;
      this.disableApprove = true;
      this.contractService
        .activateContractVerification(
          awaitingEscalation.contractVerificationContracts[0].id
        )
        .subscribe(
          (data) => {
            if (data) {
              this.toastr.success(
                "Customer registration has been activated successfully!",
                "Successful"
              );
              this.closeModal();
              this.formNotValid = false;
              this.ref.detectChanges();
              this.resetFormCheck();
              this.getAwaitingReviewCustomers({
                page: 0,
                size: this.perPage,
                customerStatus: this.getStatusForFilter(),
                isAccessorySale: this.kycType == "COOKSTOVE_ACCESSORY",
              });
              this.loadingApprove = false;
              this.disableApprove = false;
            } else {
              this.showMessage = true;
              this.loadingApprove = false;
              this.disableApprove = false;
            }
          },
          (error: Error) => {
            this.toastr.error(error.message, "Error");
            console.error("Error occurred:", error);
          },
          () => {
            this.loadingApprove = false;
            this.disableApprove = false;
          }
        );
    }
  }
  resetFormCheck() {
    this.sepDetailCheck = false;
    this.customerDetailCheck = false;
    this.productDetailCheck = false;
  }

  resubmitVerification(awaitingEscalation) {
    this.validatePreference();
    this.checkFormValidity();
    if (
      !this.sepDetailCheck &&
      !this.customerDetailCheck &&
      !this.productDetailCheck
    ) {
      this.formNotValid = true;
    }
    if (this.additionalKycInfo === "" || this.additionalKycInfo === null) {
      this.resubmissionCheck = true;
    }
    if (this.isFormValid && this.additionalKycInfo !== null) {
      this.loadingResubmit = true;
      this.disableResubmit = true;
      this.contractService
        .returnContractVerification(
          awaitingEscalation.contractVerificationContracts[0].id,
          {
            additionalComments: this.additionalComments,
            additionalKycInfo: this.additionalKycInfo,
          }
        )
        .subscribe(
          (data) => {
            if (data) {
              this.toastr.success(
                "Additional request resubmission has been sent successfully!",
                "Successful"
              );

              this.closeModal();
              this.resubmissionCheck = false;
              this.formNotValid = false;
              this.ref.detectChanges();
              this.resetFormCheck();
              this.getAwaitingReviewCustomers({
                page: 0,
                size: this.perPage,
                customerStatus: this.getStatusForFilter(),
                isAccessorySale: this.kycType == "COOKSTOVE_ACCESSORY",
              });
              this.loadingResubmit = false;
              this.disableResubmit = false;
            } else {
              this.showMessage = true;
              this.loadingResubmit = false;
              this.disableResubmit = false;
            }
          },
          (error: Error) => {
            this.toastr.error(error.message, "Error");
            console.error("Error occurred:", error);
          },
          () => {
            this.loadingResubmit = false;
            this.disableResubmit = false;
          }
        );
    }
  }

  requestCallBackVerification(awaitingEscalation) {
    this.callbackCheck = true;
    if (!!this.callBackInfo || this.callBackInfo != '') {
      const date = new Date(this.callBackInfo);
      const unixTimestamp = date.getTime();
      if (this.callBackInfo) {
        this.contractService
          .requestedCallback(
            awaitingEscalation.contractVerificationContracts[0].id, unixTimestamp).subscribe(a => console.log(a));
      }

    }
  }

  rejectVerification(awaitingEscalation) {
    this.validatePreference();
    this.checkFormValidity();
    if (
      !this.sepDetailCheck &&
      !this.customerDetailCheck &&
      !this.productDetailCheck
    ) {
      this.formNotValid = true;
    }
    if (this.rejectionInfo === "" || this.rejectionInfo === null) {
      this.rejectionCheck = true;
    }

    if (this.isFormValid && this.rejectionInfo !== null) {
      this.loadingReject = true;
      this.disableReject = true;
      this.contractService
        .rejectContractVerification(
          awaitingEscalation.contractVerificationContracts[0].id,
          {
            additionalComments: this.additionalComments,
            additionalKycInfo: this.rejectionInfo,
          }
        )
        .subscribe(
          (data) => {
            if (data) {
              this.toastr.success(
                "Customer registration has been rejected successfully!",
                "Successful"
              );
              this.rejectionCheck = false;
              this.formNotValid = false;
              this.closeModal();
              this.ref.detectChanges();
              this.resetFormCheck();
              this.getAwaitingReviewCustomers({
                page: 0,
                size: this.perPage,
                customerStatus: this.getStatusForFilter(),
                isAccessorySale: this.kycType == "COOKSTOVE_ACCESSORY",
              });
              this.loadingReject = false;
              this.disableReject = false;
            } else {
              this.showMessage = true;
              this.loadingReject = false;
              this.disableReject = false;
            }
          },
          (error: Error) => {
            this.toastr.error(error.message, "Error");
            console.error("Error occurred:", error);
          },
          () => {
            this.loadingReject = false;
            this.disableReject = false;
          }
        );
    }
  }

  blacklistVerification(awaitingEscalation) {
    this.validatePreference();
    this.checkFormValidity();
    if (
      !this.sepDetailCheck &&
      !this.customerDetailCheck &&
      !this.productDetailCheck
    ) {
      this.formNotValid = true;
    }
    if (this.blacklistInfo === "" || this.blacklistInfo === null) {
      this.blacklistCheck = true;
    }
    if (this.isFormValid && this.blacklistInfo !== null) {
      this.loadingBlacklist = true;
      this.disableBlacklist = true;
      this.contractService
        .blacklistContractVerification(
          awaitingEscalation.contractVerificationContracts[0].id,
          {
            additionalComments: this.additionalComments,
            additionalKycInfo: this.additionalKycInfo,
          }
        )
        .subscribe(
          (data) => {
            if (data) {
              this.toastr.success(
                "Customer registration has been blacklisted successfully!",
                "Successful"
              );
              this.closeModal();
              this.resetFormCheck();
              this.ref.detectChanges();
              this.getAwaitingReviewCustomers({
                page: 0,
                size: this.perPage,
                customerStatus: this.getStatusForFilter(),
                isAccessorySale: this.kycType == "COOKSTOVE_ACCESSORY",
              });
              this.loadingBlacklist = false;
              this.disableBlacklist = false;
              this.blacklistCheck = false;
            } else {
              this.showMessage = true;
              this.loadingBlacklist = false;
              this.disableBlacklist = false;
            }
          },
          (error: Error) => {
            this.toastr.error(error.message, "Error");
            console.error("Error occurred:", error);
          },
          () => {
            this.loadingBlacklist = false;
            this.disableBlacklist = false;
          }
        );
    }
  }
  get config() {
    return {
      statusName: this.statusName,
      canApproveAwaitingMoreInfo:
        this.can("can-approve-awaiting-more-info-customers") === true,
      popoverTitle: this.popoverTitle,
      popoverMessage: this.popoverMessage,
      loadingApprove: this.loadingApprove,
      disableApprove: this.disableApprove,
      assignedUserId: this.assignedUserId,
      user: this.user,
      loadingResubmit: this.loadingResubmit,
      disableResubmit: this.disableResubmit,
      assignedUser: this.assignedUser,
      loadingReject: this.loadingReject,
      disableReject: this.disableReject,
      loadingBlacklist: this.loadingBlacklist,
      disableBlacklist: this.disableBlacklist,
      isAllScoreAnswered: this.isAllScoreAnswered,
    };
  }
}

import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedComponentModule} from '../../shared-component/shared-component.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {RouterModule} from '@angular/router';
import {NgxMaskModule} from 'ngx-mask';
import { TranslateModule } from '@ngx-translate/core';
import {PlaygroundComponent} from "@appContainers/ticketing/components/component/playground-component";
import {SortablejsModule} from "@dustfoundation/ngx-sortablejs";
import {IssuesComponent} from "@appContainers/ticketing/components/table/issues-component";
import {MenuModule} from "headlessui-angular";
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import {
  TeamsTableComponent
} from "@appContainers/ticketing/components/teams/teams-table/teams-table-component";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {
  CreateTeamComponent
} from "@appContainers/ticketing/components/teams/create-team-component/create-team-component";
import {
  UpdateTeamComponent
} from "@appContainers/ticketing/components/teams/update-team-component/update-team-component";
import {
  ProjectsTableComponent
} from "@appContainers/ticketing/components/projects/projects-table/projects-table.component";
import {
  CreateProjectComponent
} from "@appContainers/ticketing/components/projects/create-project-component/create-project-component";
import {GojsAngularModule} from "gojs-angular";
import {LibraryComponent} from "@appContainers/ticketing/components/projects/library-component/library-component";
import {
  WorkflowTableComponent
} from "@appContainers/ticketing/components/workflows/workflow-table/workflow-table-component";
import {
  CreateWorkflowComponent
} from "@appContainers/ticketing/components/workflows/create-workflow-component/create-workflow-component";
import {
  UpdateWorkflowComponent
} from "@appContainers/ticketing/components/workflows/update-workflow-component/update-workflow-component";
import {
  DispositionsmapTableComponent
} from "@appContainers/ticketing/components/dispositionsmap/dispositionsmap-table/dispositionsmap-table-component";
import {
  DispositionsmapCreateComponent
} from "@appContainers/ticketing/components/dispositionsmap/dispositionsmap-create/dispositionsmap-create-component";
import {
  DispositionsmapUpdateComponent
} from "@appContainers/ticketing/components/dispositionsmap/dispositionsmap-update/dispositionsmap-update-component";
import {
  CallToActionRulesCreateComponent
} from "@appContainers/ticketing/components/call-to-action-rules/call-to-action-rules-create/call-to-action-rules-create-component";
import {MentionModule} from "angular-mentions";
import {
  CallToActionRulesTableComponent
} from "@appContainers/ticketing/components/call-to-action-rules/call-to-action-rules-table/call-to-action-rules-table-component";
import {
  CallToActionRulesUpdateComponent
} from "@appContainers/ticketing/components/call-to-action-rules/call-to-action-rules-update/call-to-action-rules-update-component";
import {TicketViewDirective} from "@appContainers/ticketing/components/ticket-view-directive/ticket-view-directive";
import {TicketCreateDirective} from "@appContainers/ticketing/components/ticket-create-directive/ticket-create-directive";
import {
  TicketTypeTableComponent
} from "@appContainers/ticketing/components/ticket-type/ticket-type-table/ticket-type-table.component";
import {
  TicketTypeCreateComponent
} from "@appContainers/ticketing/components/ticket-type/ticket-type-create/ticket-type-create-component";
import {
  TicketTypeUpdateComponent
} from "@appContainers/ticketing/components/ticket-type/ticket-type-update/ticket-type-update-component";
import {
  TicketDetailsPageComponent
} from "@appContainers/ticketing/components/ticket-details-page/ticket-details-page-component";
import {MyIssuesComponent} from "@appContainers/ticketing/components/my-issues/my-issues.component";
import {
  CustomfieldsTableComponent
} from "@appContainers/ticketing/components/customfields/customfields-table/customfields-table.component";
import {
  CustomfieldsCreateComponent
} from "@appContainers/ticketing/components/customfields/customfields-create/customfields-create-component";
import {
  CustomfieldsUpdateComponent
} from "@appContainers/ticketing/components/customfields/customfields-update/customfields-update.component";
import {
  WarrantyTicketDirective
} from "@appContainers/ticketing/components/system-fields-directives/warranty-ticket-directive/warranty-ticket-directive";
import {
  TicketStatusTableComponent
} from "@appContainers/ticketing/components/ticket-status/ticket-status-table/ticket-status-table.component";
import {
  TicketStatusCreateComponent
} from "@appContainers/ticketing/components/ticket-status/ticket-status-create/ticket-status-create.component";
import {
  TicketStatusUpdateComponent
} from "@appContainers/ticketing/components/ticket-status/ticket-status-update/ticket-status-update.component";
import {
  BaseSystemFieldDirective
} from "@appContainers/ticketing/components/system-fields-directives/base-system-field-directive";
import {
  RequestTypeTableComponent
} from "@appContainers/ticketing/components/request-type/request-type-table/request-type-table.component";
import {
  RequestTypeCreateComponent
} from "@appContainers/ticketing/components/request-type/request-type-create/request-type-create.component";
import {
  RequestTypeUpdateComponent
} from "@appContainers/ticketing/components/request-type/request-type-update/request-type-update.component";
import {
  TicketCreatePageComponent
} from "@appContainers/ticketing/components/ticket-create-page/ticket-create-page.component";
import {
  RepossessionTicketDirective
} from "@appContainers/ticketing/components/system-fields-directives/repossession-ticket-directive/repossession-ticket-directive";
import {
  WarrantyTicketViewDirective
} from "@appContainers/ticketing/components/system-fields-directives/warranty-ticket-view-directive/warranty-ticket-view-directive";
import {
  RepossessionTicketViewDirective
} from "@appContainers/ticketing/components/system-fields-directives/repossession-ticket-view-directive/repossession-ticket-view-directive";
import {
  TicketDashboardComponent
} from "@appContainers/ticketing/components/ticket-dashboard/ticket-dashboard.component";


export const routes = [
  {
    path: '',
    component: PlaygroundComponent,
  },
  {
    path: 'dashboard',
    component: TicketDashboardComponent,
  },
  {
    path: 'issues',
    component: IssuesComponent,
  },
  {
    path: 'myissues',
    component: MyIssuesComponent,
  },
  {
    path: 'teams',
    component: TeamsTableComponent,
  },
  {
    path: 'newTeam',
    component: CreateTeamComponent,
  },
  {
    path: 'updateTeam/:id',
    component: UpdateTeamComponent,
  },
  {
    path: 'projects',
    component: ProjectsTableComponent,
  },
  {
    path: 'newProject',
    component: CreateProjectComponent,
  },
  {
    path: 'workflows',
    component: WorkflowTableComponent,
  },
  {
    path: 'newWorkflow',
    component: CreateWorkflowComponent,
  },
  {
    path: 'workflow/:id',
    component: UpdateWorkflowComponent,
  },
  {
    path: 'dispositionsmaps',
    component: DispositionsmapTableComponent,
  },
  {
    path: 'newDispositionsMap',
    component: DispositionsmapCreateComponent,
  },
  {
    path: 'dispositionsmap/:id',
    component: DispositionsmapUpdateComponent,
  },
  {
    path:'calltoactionrules',
    component: CallToActionRulesTableComponent
  },
  {
    path:'newCallToActionRules',
    component: CallToActionRulesCreateComponent
  },
  {
    path:'calltoactionrules/:id',
    component: CallToActionRulesUpdateComponent
  },
  {
    path:'tickettype',
    component: TicketTypeTableComponent
  },
  {
    path:'newTicketType',
    component: TicketTypeCreateComponent
  },
  {
    path:'ticketType/:id',
    component: TicketTypeUpdateComponent
  },
  {
    path:'ticket/:id',
    component: TicketDetailsPageComponent
  },
  {
    path:'customfields',
    component: CustomfieldsTableComponent
  },
  {
    path:'newCustomField',
    component: CustomfieldsCreateComponent
  },
  {
    path:'customField/:id',
    component: CustomfieldsUpdateComponent
  },
  {
    path:'ticketstatuses',
    component: TicketStatusTableComponent
  },
  {
    path:'ticketstatuses/newTicketStatus',
    component: TicketStatusCreateComponent
  },
  {
    path:'ticketStatus/:id',
    component: TicketStatusUpdateComponent
  },
  {
    path:'requesttypes',
    component: RequestTypeTableComponent
  },
  {
    path:'newRequestType',
    component: RequestTypeCreateComponent
  },
  {
    path:'requestType/:id',
    component: RequestTypeUpdateComponent
  },
  {
    path:'newTicket',
    component: TicketCreatePageComponent
  },
  {
    path:'newWarrantyTicket',
    component: TicketCreatePageComponent
  },
  {
    path:'newRepossessionTicket',
    component: TicketCreatePageComponent
  },
];


@NgModule({
    declarations: [
      TicketCreatePageComponent,
      RequestTypeTableComponent,
      RequestTypeCreateComponent,
      RequestTypeUpdateComponent,
      BaseSystemFieldDirective,
      RepossessionTicketViewDirective,
      WarrantyTicketDirective,
      WarrantyTicketViewDirective,
      RepossessionTicketDirective,
      TicketStatusTableComponent,
      TicketStatusCreateComponent,
      TicketStatusUpdateComponent,
      TicketDashboardComponent,
      PlaygroundComponent, IssuesComponent, TeamsTableComponent, CreateTeamComponent, UpdateTeamComponent, ProjectsTableComponent, CreateProjectComponent, LibraryComponent, WorkflowTableComponent, CreateWorkflowComponent, UpdateWorkflowComponent, DispositionsmapTableComponent, DispositionsmapCreateComponent, DispositionsmapUpdateComponent, CallToActionRulesCreateComponent, CallToActionRulesTableComponent, CallToActionRulesUpdateComponent, TicketViewDirective, TicketCreateDirective, TicketTypeTableComponent, TicketTypeCreateComponent, TicketTypeUpdateComponent, TicketDetailsPageComponent, MyIssuesComponent, CustomfieldsTableComponent, CustomfieldsCreateComponent, CustomfieldsUpdateComponent],
    imports: [
        SharedComponentModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        RouterModule.forChild(routes),
        NgxMaskModule.forRoot(),
        TranslateModule,
        MenuModule,
        SortablejsModule,
        EditorModule,
        TooltipModule.forRoot(),
        GojsAngularModule,
        MentionModule,
    ],
    providers: [

        {provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'}
    ],
    exports: [
        TicketViewDirective,
        TicketCreateDirective
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PlaygroundModule { }

<div *ngIf="routerUrl$ | async as routerUrl">
  <div *ngIf="routerUrl === '/home'" class="app_wrapper">
    <!-- <div class="options_screen grid-container" *ngIf="routerUrl === '/home'" > -->

    <!-- <sidebar-cmp class="Sidebar" [moduleUrl]="routerUrl === '/home'"  [(isOpen)]="isSidebarOpen" [(isCountryOpen)]="isCountryOpen" ></sidebar-cmp> -->
    <div class="app_content">

      <!-- <div class="app_header">
          <div class="app_header_content">
              <div class="p-t-20 m-t-20">
                  <span class="" style="top: 0; padding-bottom: 20px;">
                      <img alt="" class="m-b-20" src="../../../assets/images/rwanda_logo_name.svg" width="300">
                  </span>
              </div>
          </div>
          <div class="app_header_content">
              <div class="app_header_right">
                  <div class="notification_panel">
                      <div (click)="logout()" class="notification_toggle">Log Out </div>
                  </div>
              </div>
          </div>
      </div> -->

      <div *ngIf="!moduleUrl" [ngClass]="{'is-open': isOpen}" class="profile_switcher ">
        <div class="switcher_toggle m-l-20 m-t-10">
          <svg (click)="profileSwitcher()" class="item_svg_icon cog_icon">
            <use xlink:href="#cog_icon"></use>
          </svg>
          <span (click)="home()" class="user_name">{{tenantName | slice:0:22}}</span>
          <svg (click)="countrySwitcher()" class="item_svg_icon">
            <use xlink:href="#expand"></use>
          </svg>
        </div>

        <div class="sidebar_header">
          <div [ngClass]="{'open': isCountryOpen}" class="partner_switcher">
            <ul class="partner_list">
              <li *ngFor='let tenant of tenants'>
                <span class="flag">
                  {{ tenant.countryFlag }}
                </span>
                <span class="text">
                  <a (click)="switchTenant(tenant)" class="link" href="javascript:void(0)">
                    {{tenant.name}}
                  </a>
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div class="profile_switcher__panel">
          <div class="panel__header">
            <span class="user_avatar">
              <img alt="" src="../../../assets/images/rwanda_logo.svg">
            </span>
            <span class="user_name">{{tenantName}}</span>
            <span (click)="profileSwitcher()" class="close_profiler"></span>
          </div>
          <!--<div class="panel_section">-->
          <!--<ul class="panel_nav">-->
          <!--<li>-->
          <!--<a href="javascript:void(0)">-->
          <!--<svg class="panel_nav_icon">-->
          <!--<use xlink:href="#cog_icon"></use>-->
          <!--</svg>-->
          <!--<span class="panel_nav_label">-->
          <!--<a href="javascript:void(0)"> Manage Settings</a>-->
          <!--</span>-->
          <!--</a>-->
          <!--</li>-->
          <!--</ul>-->
          <!--</div>-->

          <div class="panel_section">
            <div class="text_exclude">{{'You are signed in as' | translate}} <strong>{{ user }}</strong></div>
            <ul class="panel_nav">
              <li>
                <a href="javascript:void(0)">
                  <svg class="panel_nav_icon">
                    <use xlink:href="#cog_icon"></use>
                  </svg>
                  <span (click)="redirectTo('/identity-management/users/profile/settings')"
                        class="panel_nav_label">{{'Manage your profile' | translate}}</span>
                </a>
              </li>
              <li *ngIf="have('can-access-phone-management')">
                <a href="javascript:void(0)">
                  <svg class="panel_nav_icon">
                    <use xlink:href="#cog_icon"></use>
                  </svg>
                  <span (click)="redirectTo('/phone-management')"
                        class="panel_nav_label">{{'Phone management' | translate}}</span>
                </a>
              </li>
              <li *ngIf="have('can-access-global-settings')">
                <a href="javascript:void(0)">
                  <svg class="panel_nav_icon">
                    <use xlink:href="#cog_icon"></use>
                  </svg>
                  <span (click)="redirectTo('/global-settings')"
                        class="panel_nav_label">{{'Atlas Settings' | translate}}</span>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <svg class="panel_nav_icon">
                    <use xlink:href="#cog_icon"></use>
                  </svg>
                  <span class="panel_nav_label" [routerLink]="['/login']"
                        (click)="logout()">{{'Sign Out' | translate}}</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="panel_section other_nav">
            <ul class="panel_nav">
              <li><a [routerLink]="['/terms-and-conditions']">{{'Terms' | translate}}</a></li>
              <li><a [routerLink]="['/privacy-policy']">{{'Privacy' | translate}}</a></li>
              <li><a [routerLink]="['/faq']">{{'FAQ' | translate}}</a></li>
              <li class="pull-right">
                <select #langSelect (change)="changeLang(langSelect.value);profileSwitcher()">
                  <option *ngFor="let lang of translate.getLangs()" [value]="lang"
                          [selected]="lang === translate.currentLang">{{ lang }}</option>
                </select>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="Modules_area" style="height: 100%; width: 100%;">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div *ngIf="routerUrl !== '/home'" class="app_wrapper">

    <div class="sidebar">
      <sidebar-cmp (click)="clicked()" *ngIf="!isActivation" [(isCountryOpen)]="isCountryOpen" [(isOpen)]="isSidebarOpen"
                   [moduleUrl]="routerUrl === '/home'" class="app_navigation"></sidebar-cmp>
    </div>


    <div class="app_content">

      <div class="app_header">
        <div *ngIf="isActivation">
          <div *ngIf="!moduleUrl" [ngClass]="{'is-open': isOpen}" class="profile_switcher">
            <div class="switcher_toggle">
              <svg (click)="profileSwitcher()" class="item_svg_icon cog_icon">
                <use xlink:href="#cog_icon"></use>
              </svg>
              <span (click)="home()" class="user_name">{{tenantName | slice:0:22}}</span>
              <svg (click)="countrySwitcher()" class="item_svg_icon">
                <use xlink:href="#expand"></use>
              </svg>
            </div>

            <div class="sidebar_header">
              <div [ngClass]="{'open': isCountryOpen}" class="partner_switcher">
                <ul class="partner_list">
                  <li *ngFor='let tenant of tenants'>
                    <span class="image">
                      <!-- <img alt="" src="assets/images/flags/malawi.svg"> -->
                      {{tenant.countryFlag}}
                    </span>
                    <span class="text">
                      <a (click)="switchTenant(tenant)" class="link" href="javascript:void(0)">
                        {{tenant.name}}
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="profile_switcher__panel">
              <div class="panel__header">
                <span class="user_avatar">
                  <img alt="" src="../../../assets/images/rwanda_logo.svg">
                </span>
                <span class="user_name">{{tenantName}}</span>
                <span (click)="profileSwitcher()" class="close_profiler"></span>
              </div>


              <div class="panel_section">
                <div class="text_exclude">{{'You are signed in as' | translate}} <strong>{{ user }}</strong></div>
                <ul class="panel_nav">
                  <li>
                    <a href="javascript:void(0)">
                      <svg class="panel_nav_icon">
                        <use xlink:href="#cog_icon"></use>
                      </svg>
                      <span class="panel_nav_label" (click)="redirectTo('/identity-management/users/profile/settings')" (click)="redirectTo('/identity-management/users/profile/settings')">{{'Manage your profile' | translate}}</span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <svg class="panel_nav_icon">
                        <use xlink:href="#cog_icon"></use>
                      </svg>
                      <span class="panel_nav_label" (click)="redirectTo('/phone-management')">{{'Phone management' | translate}}</span>
                    </a>
                  </li>
                  <li *ngIf="have('can-access-global-settings')">
                    <a href="javascript:void(0)">
                      <svg class="panel_nav_icon">
                        <use xlink:href="#cog_icon"></use>
                      </svg>
                      <span (click)="redirectTo('/global-settings')"
                            class="panel_nav_label">{{'Atlas Settings' | translate}}</span>
                    </a>
                  </li>
                  <li *ngIf="have('can-access-global-settings')">
                    <a href="javascript:void(0)">
                      <svg class="panel_nav_icon">
                        <use xlink:href="#cog_icon"></use>
                      </svg>
                      <span (click)="redirectTo('/global-settings')"
                            class="panel_nav_label">{{'Atlas Settings' | translate}}</span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <svg class="panel_nav_icon">
                        <use xlink:href="#cog_icon"></use>
                      </svg>
                      <span class="panel_nav_label" [routerLink]="['/login']"
                            (click)="logout()">{{'Sign Out' | translate}}</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="panel_section other_nav">
                <ul class="panel_nav">
                  <li><a href="/terms-and-conditions">{{'Terms'  | translate}}</a></li>
                  <li><a href="/privacy-policy">{{'Privacy' | translate}}</a></li>
                  <li><a href="/faq">{{'FAQ' | translate}}</a></li>
                  <li class="pull-right">
                    <select #langSelect (change)="changeLang(langSelect.value);profileSwitcher()">
                      <option *ngFor="let lang of translate.getLangs()" [value]="lang"
                              [selected]="lang === translate.currentLang">{{ lang }}</option>
                    </select>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!isActivation" (click)="clickedHeader()" class="app_header_content">
          <nav role="navigation" class="navigation">
            <div id="menuToggle">
              <input type="checkbox" />
              <span></span>
              <span></span>
              <span></span>
              <ul id="menu">
                <sidebar-cmp (click)="clicked()" *ngIf="!isActivation" [(isCountryOpen)]="isCountryOpen" [(isOpen)]="isSidebarOpen"
                 [moduleUrl]="routerUrl === '/home'" class="app_navigation"></sidebar-cmp>
              </ul>


            </div>
          </nav>
          <div [ngClass]="{'open': isOpen}" class="app_header_left">
            <div (click)="onNavClick()" class="sr_heading_title" data-toggle="dropdown">
              <span class="sr_heading_title_label">{{ getNameFromRoute(routerUrl) }}</span>
              <svg class="sr_heading_title_icon">
                <use xlink:href="#open-menu"></use>
              </svg>
            </div>
            <ul class="module_switcher_panel">
              <li *ngFor="let menuItem of myModules;  let i = index">
                <a (click)="onClick(menuItem.route);onNavClick()"
                   [ngClass]="{'is-current': menuItem.name.toLowerCase() === routerUrl.split('/')[1] }"
                   class="dropdown_menu_item  " href="javascript:void(0)">
                  <span class="dropdown_menu_item_icon">
                    <img alt="" src="{{menuItem.icon}}">
                  </span>
                  <span class="dropdown_menu_item_label">
                    {{menuItem.name | translate}}
                  </span>
                </a>
              </li>
            </ul>

            <div *ngIf="routerUrl.split('/')[1] === 'crm'" class="app_global_search">
              <form action="">
                <!-- <input (click)="searchCustomer(routerUrl.split('/')[1])" class="search_btn" type="submit"
                  value="Search"> -->
                <input [(ngModel)]="query" [placeholder]="'Search for Customers'" class="search_input" name="query"
                       type="text" (change)="searchCustomer(routerUrl.split('/')[1])">
                <select [(ngModel)]="searchType" class="search_btn" type="submit" name="searchType" value="Search"
                        (change)="searchCustomer(routerUrl.split('/')[1])">
                  <option value="searchAccountNumber">{{'Account Number' | translate}}</option>
                  <option value="searchCustomerName">{{'Name' | translate}}</option>
                  <option value="searchPhoneNumber">{{'Phone Number' | translate}}</option>
                  <option value="searchIdentificationType">{{'Identification Type' | translate}}</option>
                </select>
              </form>
            </div>
          </div>
          <div *ngIf="routerUrl.split('/')[1] === 'knowledge-base'" class="app_global_search m-t-24">
            <form action="">
              <input [(ngModel)]="query" [placeholder]="'Search for Pages'" class="search_input" name="query"
                     type="text" (change)="searchPage(routerUrl.split('/')[1])">
              <input (click)="searchPage(routerUrl.split('/')[1])" class="search_btn" type="submit"
                     value="Search">
            </form>
          </div>
          <div class="app_header_right">
            <button *ngIf="routerUrl.split('/')[1] === 'crm' && isAbleToRegisterCustomer()" [routerLink]="['/crm/customers/registration']"
                    class="button_primary">{{'ADD CUSTOMER' | translate}}
            </button>
            <div *hasViewAccess>
            <button *ngIf="routerUrl.split('/')[1] === 'crm' && isAbleToRegisterPortableCustomer()" [routerLink]="['/crm/customers/portable-registration']"
                    class="button_primary">{{'PORTABLE REGISTRATION' | translate}}
            </button>
          </div>
            <button *ngIf="routerUrl.split('/')[1] === 'knowledge-base'" (click)="openModal(template)"  class="button_primary">{{'ADD' | translate }}
            </button>

            <!-- create a space or page -->
            <ng-template #template>
              <div>
                <div class="modal-header">
                  <strong>{{'Create' | translate}}</strong>
                  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="flex_item">
                    <a [routerLink]="['/knowledge-base/spaces/create']" class="sector_card" (click)="modalRef.hide()">
                      <img alt="" src="assets/images/crm.svg">
                      <h4>{{ 'Space' | translate}}</h4>
                    </a>
                    <a [routerLink]="['/knowledge-base/pages/create']" class="sector_card" (click)="modalRef.hide()">
                      <img alt="" src="assets/images/finance.svg">
                      <h4>{{ 'Page' | translate}}</h4>
                    </a>
                  </div>

                  <!-- <div class="modal-footer">
                    <button class="button_secondary pull-right primary text-white" type="button" (click)="create()">{{'Create' | translate}}</button>
                    <button class="button_secondary pull-right text-white" type="button" (click)="modalRef.hide()">{{'Close' | translate}}</button>
                  </div> -->
                </div>
              </div>
            </ng-template>
             <!-- end create a space or page -->

            <div style="padding: 15px"
                 *ngIf="(routerUrl.split('/')[1] === 'warranty-and-service' || routerUrl.split('/')[1] === 'inventory') && isRepairCentreUser()">
              <h4>
                <a href="javascript:void(0)" (click)="showRepairCentreSelection()">
                  {{toUpper('Repair Centre' | translate)}}: {{repairCentreName}}
                  <i [ngClass]="{'fa fa-caret-down': !selectRepairCentreOpen}"></i> </a>
              </h4>
              <div *ngIf="selectRepairCentreOpen">
                <div *ngIf="repairCentres$ | async as repairCentres" [ngClass]="{'open': selectRepairCentreOpen}">
                  <ul class="repair-centre-picker" *ngIf="repairCentres.length > 0">
                    <li *ngFor='let repairCentre of repairCentres'>
                      <span class="text">
                        <a class="link" href="javascript:void(0)" (click)="selectRepairCentre(repairCentre)">
                                {{repairCentre.name}}
                        </a>
                      </span>
                    </li>
                  </ul>
                  <p>{{'This user is not assigned to any repair centre' | translate}}</p>
                </div>
              </div>
            </div>

            <!-- <button class="button_primary" [routerLink]="['/setup/rates/registration']" *ngIf="routerUrl.split('/')[1] === 'setup'">ADD RATE</button> -->

            <div class="notification_panel">
              <div id="notification-circle" class="circle orange"></div>
              <div (click)="$event.stopPropagation();onNotificationClick()" id="bell-icon" class="bell-icon"><i class="fa fa-bell-o"></i>

                <!-- <span class="has_count">15</span> -->
              </div>
            </div>
          </div>

        </div>
      </div>
      <div (click)="clickedMain()">
        <router-outlet></router-outlet>
      </div>

    </div>

    <div class="notification_widget box-shadow-pop" *ngIf="isNotificationOpen">
      <!--      <div class="notification_widget_header">-->
      <!--        <span (click)="onNotificationClick()" class="close_profiler"></span>-->
      <!--      </div>-->

      <div style="display:flex;justify-content: space-between;align-items:center;padding-right:5px;padding-left:5px">
        <div class="tabs-wrapper  mb-2">
          <div class="tabs" style="border-bottom: 1px dashed #D2D2D2">
            <p class="tab" (click)="notificationActiveTab = 1" [ngClass]="{'active' : notificationActiveTab == 1}">Unread</p>
            <p class="tab" (click)="notificationActiveTab = 2"  [ngClass]="{'active' : notificationActiveTab == 2}">Read</p>
            <p class="tab" (click)="notificationActiveTab = 3"  [ngClass]="{'active' : notificationActiveTab == 3}">Info</p>
          </div>
        </div>
        <div>
          <svg style="cursor:pointer" (click)="isNotificationOpen=false" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 0.96 0.96" xml:space="preserve"><path d="M.87.48a.39.39 0 0 1-.39.39.39.39 0 0 1-.39-.39.39.39 0 0 1 .78 0" style="fill:#e54d2e"/><path d="M.48.09v.78a.39.39 0 0 0 0-.78" style="fill:#f27261"/><path d="M.48.09a.39.39 0 1 1-.001.781A.39.39 0 0 1 .48.09m0-.09a.48.48 0 1 0 0 .96.48.48 0 0 0 0-.96m.064.48L.618.406A.045.045 0 1 0 .554.342L.48.416.406.342a.045.045 0 1 0-.064.064L.416.48.342.554a.045.045 0 1 0 .064.064L.48.544l.074.074q.014.013.032.013C.604.631.609.627.618.618a.045.045 0 0 0 0-.064z" style="fill:#f4d6b0"/></svg>
        </div>
      </div>

      <div *ngIf="notificationActiveTab == 1" >
        <div *ngFor="let notification of unreadNotifications">
          <div (click)="clickOnNotification(notification)" class="notification-row">


            <div class="notification-header">
              <div class="flex-row-center">
                <div class="css-avatar" style="color:white" [ngStyle]="{'background':notification.userColorCode}">{{notification.userInitials}}</div>
                <div>{{notification.userFirstName + ' ' + notification.userLastName}}</div>
              </div>

              <div [tooltip]="moment(notification.date).format('DD MMMM YYYY HH:mm')">{{moment(notification.date).fromNow() }}</div>
            </div>
            <div class="notification-subject">{{notification.subject}}</div>
            <div class="notification-body">{{notification.body}}</div>
          </div>
        </div>
      </div>

      <div *ngIf="notificationActiveTab == 2" >
        <div *ngFor="let notification of readNotifications">
          <div (click)="clickOnNotification(notification)" class="notification-row">


            <div class="notification-header">
              <div class="flex-row-center">
                <div class="css-avatar" style="color:white" [ngStyle]="{'background':notification.userColorCode}">{{notification.userInitials}}</div>
                <div>{{notification.userFirstName + ' ' + notification.userLastName}}</div>
              </div>

              <div [tooltip]="moment(notification.date).format('DD MMMM YYYY HH:mm')">{{moment(notification.date).fromNow() }}</div>
            </div>
            <div class="notification-subject">{{notification.subject}}</div>
            <div class="notification-body">{{notification.body}}</div>
          </div>
        </div>
      </div>

      <ul class="notif_tab_menu">



        <li *ngIf="notificationActiveTab == 3" [ngClass]="{'active': notificationActiveTab === 3}" class="nav-item">
          <a (click)="changeTab(1)" class="nav-link" data-toggle="tab" href="javascript:void(0)"
             id="one-tab">{{'What is New in Atlas' | translate}}</a>
        </li>
        <!-- <li [ngClass]="{'active': notificationActiveTab === 2}" class="nav-item">
          <a (click)="changeTab(2)" class="nav-link" data-toggle="tab" href="javascript:void(0)"
             id="two-tab">Notifications <span class="count">6</span></a>
        </li> -->
      </ul>

      <div *ngIf="notificationActiveTab == 3" class="tab-content" style="overflow-y: auto; height: 100%; padding-bottom: 100px;">
        <div [ngClass]="{'active': notificationActiveTab === 3, 'show': notificationActiveTab === 3}"
             class="tab_pane fade" id="news">
          <!-- <div class="subscribe_news">
            <h4>What's New</h4>
            <div class="check_form">
              <input checked="" id="remember_me" style="display: inline-block;" type="checkbox"
                     value="lsRememberMe"> <label for="remember_me">Notify me about updates</label>
            </div>
          </div> -->
          <div class="news_update">
            <!-- <div class="single_news">
              <h5 class="date_posted">June 11 2019</h5>
              <h4 class="news_title">The new X1000B</h4>
              <div class="news_excerpt">
                <img alt="" class="inline_img" src="../../../assets/images/x1000B.png">
                <p>The revolutionary d.light X-series solar home system grows with your aspirations...
                </p>
              </div>
              <a class="learn_more" href="">
                <span>Learn More</span>
                <svg class="sr_heading_title_icon">
                  <use xlink:href="#open-menu"></use>
                </svg>
              </a>
            </div> -->
            <div class="single_news">
              <h4 class="news_title">General</h4>
              <div class="news_excerpt">
                <p>The new and improved version of Atlas, dubbed V4, is now available to you and other users in your
                  organization. The new Atlas builds on our experiences distributing PAYG to customers directly and
                  through our network of great distribution partners.
                </p>
              </div>
            </div>
            <div class="single_news">
              <h4 class="news_title">High-level Changes</h4>
              <div class="news_excerpt">
                <ul>
                  <li>New look and feel: a new modern design with a better UI</li>
                  <li>Improved usability: additional hyperlinks and easy navigation</li>
                  <li>Improved performance: a new back-end system architecture means increased capacity, speed, and
                    reliability
                  </li>
                </ul>
              </div>
            </div>
            <div class="single_news">
              <!-- <h5 class="date_posted">May 11 2020</h5> -->
              <h4 class="news_title">Minimum Required Browsers</h4>
              <div class="news_excerpt">
                <ul>
                  <li>Chrome: Latest version for your operating system</li>
                  <li>Firefox: Latest regular and ESR versions for your operating system</li>
                  <li>Edge: Latest version for Windows</li>
                  <li>Safari: Latest version for Mac</li>
                </ul>
              </div>

            </div>
            <div class="single_news">
              <h4 class="news_title">Atlas Version 4.0.0 Changes</h4>
              <div class="news_excerpt">
                <ul>
                  <li>A new look and feel for enhanced user experience</li>
                  <li>More powerful back-end architecture and technology</li>
                  <li>More flexibility to manage users and their accessibility by defining your roles and permissions,
                    as well the ability to create users in bulk and validate the user’s emails
                  </li>
                  <li>Full control over rate plan setup and management for products and accessories. This will
                    significantly reduce the requests for rate plan changes through Jira tickets
                  </li>
                  <li>Full control of the information you need to collect from your customers by adding custom fields on
                    the CRM customer registration form as well as on calls and actions
                  </li>
                  <li>Ability to define which calls and actions from the CRM should be escalated</li>
                  <li>Ability to define your unique customer statuses</li>
                  <li>Ability to define and manage your sales staff structure and stocking points</li>
                  <li>Ability to define your sales staff commissions triggers according to multiple predefined events,
                    to select the conditions to pay out the commission, as well as to establish the values for the
                    commission
                  </li>
                </ul>
              </div>

              <!-- <a class="learn_more" href="">
                <span>View More</span>
                <svg class="sr_heading_title_icon">
                  <use xlink:href="#open-menu"></use>
                </svg>
              </a> -->
            </div>
            <div class="single_news">
              <h4 class="news_title">Known Issues and limitations in this version 4.0.0</h4>
              <div class="news_excerpt">
                <ul>
                  <li>Reports and dashboards within various modules are limited</li>
                  <li>Knowledge Base will be limited</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div [ngClass]="{'active': notificationActiveTab === 2, 'show': notificationActiveTab === 2}"
             class="tab_pane fade" id="notice">
          <div class="tasks_section">
            <h3 class="section_title">Escalations</h3>
            <ul class="escalation_list">
              <li>
                <a class="dropdown_menu_item" href="">
                  <span class="dropdown_menu_item_icon">
                    <img alt="" src="../../../assets/images/crm_icon.svg">
                  </span>
                  <span class="dropdown_menu_item_label">CRM Tasks <span class="count">2</span></span>
                </a>
              </li>
              <li>
                <a class="dropdown_menu_item" href="inventory.html">
                  <span class="dropdown_menu_item_icon">
                    <img alt="" src="../../../assets/images/inventory_icon.svg">
                  </span>
                  <span class="dropdown_menu_item_label">Inventory Tasks <span class="count">3</span></span>
                </a>
              </li>
              <li>
                <a class="dropdown_menu_item" href="">
                  <span class="dropdown_menu_item_icon">
                    <img alt="" src="../../../assets/images/escalate_icon.svg">
                  </span>
                  <span class="dropdown_menu_item_label">Shipments <span class="count">1</span></span>
                </a>
              </li>
            </ul>
          </div>
          <div class="tasks_section">
            <h3 class="section_title">Tasks</h3>
            <div class="single_news">
              <h5 class="date_posted">June 11 2019</h5>
              <div class="news_excerpt">
                <p>
                  I was Loading a token and then moved to another phone it is now saying that the
                  token is incompatible however it is already completely up to date.
                </p>
              </div>
              <a class="learn_more" href="">
                <span>Open Task</span>
                <svg class="sr_heading_title_icon">
                  <use xlink:href="#open-menu"></use>
                </svg>
              </a>
            </div>
            <div class="single_news">
              <h5 class="date_posted">June 11 2019</h5>
              <div class="news_excerpt">
                <p>
                  I was Loading a token and then moved to another phone it is now saying that the
                  token is incompatible however it is already completely up to date.
                </p>
              </div>
              <a class="learn_more" href="">
                <span>Open Task</span>
                <svg class="sr_heading_title_icon">
                  <use xlink:href="#open-menu"></use>
                </svg>
              </a>
            </div>
            <div class="single_news">
              <h5 class="date_posted">June 11 2019</h5>
              <div class="news_excerpt">
                <p>
                  I was Loading a token and then moved to another phone it is now saying that the
                  token is incompatible however it is already completely up to date.
                </p>
              </div>
              <a class="learn_more" href="">
                <span>Open Task</span>
                <svg class="sr_heading_title_icon">
                  <use xlink:href="#open-menu"></use>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="routerUrl$ | async as routerUrl" style="position:relative;">
      <div *ngIf="routerUrl.split('/')[1] === 'crm' && router.url != '/crm/escalations/awaiting-review' && !disableCallButton"
           style="position:absolute;bottom:0;right:0;width:135px; margin-right: 50px;">
          <p class="button_primary btn-style" (click)="callTrayOpen = true">
            {{'Create Call' | translate}}
          </p>
      </div>

      <div>
        <div *ngIf="callTrayOpen" class="call-container">

          <div style="display: flex;
            -webkit-box-align: center;
            align-items: center;
            margin-bottom: 20px;">

            <label *ngIf="enableForm" class="dot"> </label>
            <span class="call m-t-10" *ngIf="enableForm ">{{'Call In Progress' | translate}}: <b>{{subscribeTimer}}</b> {{'Seconds' | translate}}</span>
            <p *ngIf="!enableForm" class="button_primary btn-call" (click)="startTimer()">
              {{'Start Call' | translate}}
            </p>

            <!-- <span style="text-align: center"> Call in progress</span> -->

            <span class="fa fa-window-minimize minimize" (click)="callTrayOpen = false"></span>

          </div>
          <hr/>
          <div class="app_customer_layout m-t-20">

            <div *ngIf="lastCallId" class="customer_products m-b-10">
              <div class="product" [ngClass]="{'open': true}">
                <div class="tab-content p-l-10 p-t-10 p-b-10 p-r-10 last-call-id">
                  <p>{{'Last call id' | translate}}:&nbsp;<span>{{lastCallId}}</span></p>
                <span class="copy-icon" (click)="copyToClipboard()"></span>
                </div>
              </div>
            </div>

            <div class="customer_products">
              <div class="product" [ngClass]="{'open': true}">
                <div class="product_tabs">

                  <div class="tab-content" style="max-height: 750px!important;">
                     <div  *ngIf="createCall.callGroup == 'Welcome Team'" >

                      <div  class="modal-right-overlay" >
                        <div>
                          <h3 class="script-title">{{'Call Script and Score' | translate}}</h3>
                        </div>

                         <div *ngIf="!startQuestion">
                           <div class="detail-container">
                             <p class="sub-title">{{'Opening Call' | translate}}:</p>
                             <p class="">{{'Hello my name is' | translate}} {{agentName}}, {{'I\'m calling from d.light. May I have a moment of your time to complete your registration by asking you a few questions' | translate}}?</p>
                             <p class="note">{{'Please note that the phone is recorded' | translate}}.</p>
                             <button class="button next" value="next" type="button" (click)="displayQuestions()">{{'Next' | translate}}<i class="fa-solid fa-arrow-right-long" style="color: #ffffff;"></i></button>

                           </div>
                          </div>

                          <div *ngIf="startQuestion" >
                          <div  class="detail-container" *ngIf="!showTotalScore">
                            <div class="rows p-t-20">
                              <div class="col-8 p-t-20">
                                <p class="index">{{'Question' | translate}} {{callScoreCardQuestions[currentQuestionIndex]?.questionOrder}} <span>/15 </span></p>
                              </div>

                                <div class="col-4">
                                <button class="button next" >{{callScoreCardQuestions[currentQuestionIndex]?.maxScoreValue}}%</button>
                                </div>
                            </div>
                            <div>

                              <p class="question p-b-10">{{callScoreCardQuestions[currentQuestionIndex]?.questionText}}</p>
                              <b>
                                <p class="description" *ngIf="currentQuestionIndex === 0">{{'Agent Guide (Agent to verify customer Details Name, Home address National ID, Primary Phone No & Alternative No)' | translate}}.</p>
                                <p class="description" *ngIf="currentQuestionIndex === 2">{{'Agent Guide: (Agent to educate the customer about stolen, lost and damaged product policy)' | translate}}.</p>
                                <p class="description" *ngIf="currentQuestionIndex === 3">{{'Agent Guide: (Agent to confirm the current area of residence of the customer)' | translate}}. </p>
                                <p class="description" *ngIf="currentQuestionIndex === 4">{{'Agent Guide: (Agent to Confirm product components - Account number & Deposit paid and other additional payment)' | translate}}. </p>
                                <p class="description" *ngIf="currentQuestionIndex === 14">{{'Agent Guide: (Please be informed that the product has a warranty of 2yrs for SHS and 1yr for Phones. The warranty does not cover broken screens, tampered units, liquid damage, burnt PCBA, or lost or stolen products)' | translate}}. </p>
                              </b>

                              <div class="p-b-20 p-t-20" >
                                <label  class="score-field " for="inputId">{{'Score' | translate}}</label>
                                <input type="number" id="inputId" name="inputScore" [(ngModel)]="callScoreCardQuestionsAnswer[callScoreCardQuestions[currentQuestionIndex]?.questionOrder].score" class="blank-input" value="_" [max]="callScoreCardQuestions[currentQuestionIndex]?.maxScoreValue">
                                <p *ngIf="callScoreCardQuestionsAnswer[callScoreCardQuestions[currentQuestionIndex]?.questionOrder].score > callScoreCardQuestions[currentQuestionIndex]?.maxScoreValue" class="error-message"> <i class="fa fa-exclamation-triangle"></i> {{'Score not allowed, check your input' | translate}}. </p>
                              </div>
                            </div>

                              <div class="rows">
                              <div class="col-5">
                                <button class="button previous" value="previous" type="button" (click)="previousQuestion()" [disabled]="isFirstQuestion()" [ngClass]="{'disabled-button-previous': isFirstQuestion()}"  >
                                  <div class="rows">
                                    <div class="col-2"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/> </svg>
                                    </div>

                                    <div class="col-5">{{'Previous' | translate}}</div>
                                  </div>

                                </button>
                              </div>
                              <div class="col-7">
                                <button *ngIf="!isLastQuestion()" class="button next" [disabled]="callScoreCardQuestionsAnswer[callScoreCardQuestions[currentQuestionIndex]?.questionOrder].score > callScoreCardQuestions[currentQuestionIndex]?.maxScoreValue" value="next" type="button"  (click)="saveScore();nextQuestion()"  [ngClass]="{'disabled-button-next': isLastQuestion()}">{{'Next' | translate}}
                                    <svg style="color: white" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" fill="white"></path>
                                  </svg>
                                </button>
                                <button *ngIf="isLastQuestion()" (click)="saveTotalScore();saveScore()"  class="button next" >{{'Finish' | translate}}
                                  <svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                                  </svg>
                                </button>
                              </div>
                            </div>

                            </div>
                          </div>

                           <div  class="detail-container" *ngIf="showTotalScore">
                            <div class="last-page ">
                              <div class="result m-b-10 m-t-40">
                                <p>{{'SCORE' | translate}}</p>
                                <p class="number p-b-20">{{scoreSum}}%</p>
                              </div>
                              <div class="m-b-10 ending">
                                <p>{{'Thank you for your time and patience and for choosing d.light' | translate}}.<br> {{'Have a wonderful day' | translate}}.</p>
                              </div>
                              <div class="m-t-40">
                                <button class="button next" value="next" type="button">
                                  {{'Finish' | translate}}
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                                  </svg>

                                </button>
                            </div>
                            </div>

                           </div>

                      </div>

                    </div>
                    <div class="tab_pane fade" id="Outbound" [ngClass]="{'show': true, 'active': true}">
                      <div>
                        <div class="form_group_modal">
                          <div class="input">
                            <label class="label_title">{{'Search Customer By' | translate}}</label>
                            <div class="btn-group">
                              <input [disabled]="!enableForm" [(ngModel)]="customerSearchQuery"
                                     [placeholder]="'Search for Customers'" name class="search_input m-r-15"
                                     name="customerSearchQuery" type="text" (change)="searchCustomerInCall($event)">
                              <select [disabled]="!enableForm" [(ngModel)]="searchType" class="search__btn w-50"
                                      type="submit" name="searchType" value="Search"
                                      (change)="searchCustomerInCall($event)">
                                <option value="searchCustomerName">{{'Name' | translate}}</option>
                                <option value="searchAccountNumber">{{'Account Number' | translate}}</option>
                                <option value="searchPhoneNumber">{{'Phone Number' | translate}}</option>
                                <option value="searchIdentificationType">{{'Identification Type' | translate}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="input m-b-10" *ngIf="customerBasicContract?.length > 0">
                            <p class="p-l-10 p-b-5">
                              <a href="javascript:void(0)" *ngFor="let customer of customerBasicContract">
                                <p (click)="selectCustomer(customer)">
                                  {{'Acc No' | translate}}: {{ customer.accountNumber }}
                                <span *ngIf="customer.contractId === createCall.contractId" class="fa fa-check p-l-10"> </span>
                                </p>
                              </a>
                            </p>
                          </div>
                          <div class="input m-b-10" *ngIf="customerBasic?.isPortable">
                            <p class="p-l-10 p-b-5">
                              <a href="javascript:void(0)">
                                <p>
                                  {{'ID' | translate}}: {{ customerBasic.identificationId }}
                                <span class="fa fa-check p-l-10"> </span>
                                </p>
                              </a>
                            </p>
                          </div>

                          <div class="input">
                            <label class="label_title">{{'Select Call Disposition' | translate}} *</label>
                            <select [disabled]="!enableForm" [(ngModel)]="createCall.callGroup" name="group" (change)="getLevel1Calls();setNotesValidation();onSelect()">
                              <option value="">{{'Select Call Disposition' | translate}}</option>
                              <option [ngValue]="callGroup" *ngFor="let callGroup of callGroups">
                                {{callGroup}}</option>
                            </select>
                          </div>
                          <div class="input" *ngIf="createCall.callGroup != null && isViMarket && showIncomingCallingNumberField && (createCall.callGroup == 'Inbound Team' || createCall.callGroup == 'Aftersales Team')">
                            <label class="label_title">{{'Incoming Calling Number' | translate}} *</label>
                            <input type="number" [(ngModel)]="createCall.incomingCallingNumber">
                          </div>
                          <div class="input" *ngIf="level1Calls?.length > 0">
                            <label class="label_title">{{'Select Level 1 Disposition' | translate}} *</label>
                            <select [disabled]="!enableForm" [(ngModel)]="createCall.level1" name="level1" (change)="getLevel2Calls();setNotesValidation()">
                              <option value="">{{'Select Level 1 Disposition' | translate}}</option>
                              <option [ngValue]="level1" *ngFor="let level1 of level1Calls">
                                {{level1}}</option>
                            </select>
                          </div>
                          <div class="input" *ngIf="level2Calls?.length > 0">
                            <label class="label_title">{{'Select Level 2 Disposition' | translate}} *</label>
                            <select [disabled]="!enableForm" [(ngModel)]="createCall.level2" name="level2" (change)="getLevel3Calls();setNotesValidation()">
                              <option value="">{{'Select Level 2 Disposition' | translate}}</option>
                              <option [ngValue]="level2" *ngFor="let level2 of level2Calls">
                                {{level2}}</option>
                            </select>
                          </div>
                          <div class="checkbox" *ngIf="createCall.level1 == 'Payment Support' && createCall.level2 == 'Paid but not reflecting'">
                                <input type="checkbox" name="sendPreToken" [(ngModel)]="createCall.sendPreToken" style="height: 15px; width: inherit;">
                                <label class="m-l-10">{{'Send pre-token' | translate}}?</label>
                          </div>
                          <div class="input" *ngIf="level3Calls?.length > 0">
                            <label class="label_title">{{'Select Level 3 Disposition' | translate}} *</label>
                            <select [disabled]="!enableForm" [(ngModel)]="createCall.level3" name="level3">
                              <option value="">{{'Select Level 3 Disposition' | translate}}</option>
                              <option [ngValue]="level3" *ngFor="let level3 of level3Calls">
                                {{level3}}</option>
                            </select>
                          </div>
                          <div class="input">
                            <label class="label_title">{{'Notes' | translate}} </label>
                            <textarea rows="1" [disabled]="!enableForm || disableNotes " [(ngModel)]="createCall.notes" name="notes"
                                      placeholder=""></textarea>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button [disabled]="!enableForm || disableSaveButton" class="button_success pull-right"
                                  type="button" (click)="saveCall()">{{'Save' | translate}}
                          </button>
                          <button class="button_secondary_grey pull-right" type="button"
                                  (click)="callTrayOpen = false; pauseTimer() ; resetForm()">{{'Cancel' | translate}}
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="svg_hidden">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <symbol id="dashboard_icon" viewBox="0 0 34 30" xmlns="http://www.w3.org/2000/svg">
        <g fill-rule="nonzero">
          <path
            d="M16.499 24.874h-14.7c-1.307 0-1.794-.489-1.795-1.8V1.804c0-1.315.469-1.8 1.806-1.8 9.876-.005 19.752-.005 29.629 0 1.29 0 1.746.476 1.747 1.769v21.345c0 1.283-.476 1.754-1.758 1.755-4.977.003-9.954.003-14.929.001zm.134-22.123c-4.328 0-8.656.014-12.983-.015-.683 0-.882.182-.879.875.027 5.867.027 11.737 0 17.61 0 .7.163.938.9.936 8.631-.025 17.263-.025 25.894 0 .694 0 .874-.2.87-.885-.026-5.845-.015-11.69-.015-17.536 0-.984 0-.985-.954-.985H16.633zM16.595 29.05h-5.224c-1.051 0-1.668-.52-1.673-1.385-.005-.865.614-1.4 1.655-1.405 3.508-.006 7.016-.006 10.524 0 1.03 0 1.653.559 1.636 1.424-.016.834-.627 1.361-1.619 1.365-1.769.009-3.535.001-5.299.001z"/>
          <path
            d="M26.282 12.495v5.225c0 1-.509 1.614-1.342 1.637-.854.023-1.421-.605-1.423-1.635-.008-3.533-.008-7.067 0-10.6 0-.989.557-1.606 1.383-1.606a1.432 1.432 0 0 1 1.382 1.6c.005 1.798 0 3.587 0 5.379zM15.222 14.534c0-1.119-.009-2.238 0-3.357a1.393 1.393 0 0 1 1.393-1.512 1.371 1.371 0 0 1 1.355 1.471c.017 2.263.017 4.526 0 6.789a1.348 1.348 0 0 1-1.388 1.431 1.366 1.366 0 0 1-1.357-1.465c-.014-1.122-.003-2.238-.003-3.357zM9.695 15.912c0 .671.016 1.342-.005 2.012a1.4 1.4 0 0 1-1.4 1.433 1.371 1.371 0 0 1-1.37-1.385 82.976 82.976 0 0 1 0-4.174 1.329 1.329 0 0 1 1.395-1.344 1.361 1.361 0 0 1 1.376 1.37v.149c.003.647.005 1.293.004 1.939z"/>
        </g>
      </symbol>
      <symbol id="escalate_icon" viewBox="0 0 31.1 29" xmlns="http://www.w3.org/2000/svg">
        <path class="ast0"
              d="M15.6 29H3.4C1.5 29 0 27.6 0 25.7v-.1c0-.6.2-1.1.5-1.6 3.8-6.9 7.6-13.9 11.3-20.8.3-.5.5-.9.8-1.4.9-1.6 2.9-2.3 4.5-1.4.6.3 1.1.8 1.4 1.4 3.7 6.7 7.4 13.5 11 20.2.4.7.7 1.3 1.1 2 .9 1.6.4 3.6-1.2 4.5-.5.3-1.1.5-1.8.4-3.9.1-8 .1-12 .1zm-.1-2h12.2c.7 0 1.3-.5 1.3-1.1 0-.3 0-.5-.2-.7-4-7.4-8-14.9-12.1-22.3-.2-.5-.7-.7-1.2-.7s-.9.3-1.1.7c-4.1 7.4-8.1 14.9-12.2 22.4-.1.2-.1.3-.2.5 0 .5.5 1.1 1.2 1.2H15.5z"/>
        <path class="ast0"
              d="M16.6 13.5v4c.1.6-.4 1.1-.9 1.1-.6.1-1.1-.4-1.1-.9v-.2-8c-.1-.6.3-1.1.9-1.2s1.1.3 1.2.9v.3c-.1 1.3-.1 2.7-.1 4zM17.6 22.8c0 1.1-.9 2.1-2.1 2.1s-2.1-.9-2.1-2.1.9-2.1 2.1-2.1c1.2 0 2.1 1 2.1 2.1z"/>
      </symbol>
      <symbol id="register_icon" viewBox="0 0 30.7 33.8" xmlns="http://www.w3.org/2000/svg">
        <path class="ast0"
              d="M6.8 14.5c3.5 3.9 9.5 4.2 13.4.6l.6-.6c1.6 1 3 2.4 4.1 4-.3.1-.7.3-1 .4-2.1.9-3.6 2.7-4.1 4.9-.4 1.5-.3 3 .2 4.4.1.2 0 .4-.1.6L18 30.7c-.1.1-.2.2-.4.2-2 .2-3.9.2-5.9.1-2.1-.1-4.2-.5-6.3-1.1-1.4-.4-2.6-1.1-3.7-2-.7-.6-1-1.5-1-2.4 0-3.6 1.5-7.1 4.2-9.5.6-.6 1.3-1 1.9-1.5z"/>
        <path class="ast0" d="M21.3 8.2c0 4.1-3.4 7.5-7.5 7.5s-7.5-3.4-7.5-7.5S9.7.7 13.8.7c4.2 0 7.5 3.4 7.5 7.5z"/>
      </symbol>
      <symbol id="group_icon" viewBox="0 0 32.2 29" xmlns="http://www.w3.org/2000/svg">
        <path class="ast0"
              d="M3.9 14.899c2.2 2.5 6.1 2.7 8.5.4.1-.1.3-.2.4-.4 1 .6 1.9 1.5 2.6 2.5l-.6.3c-1.3.6-2.3 1.8-2.6 3.2-.2.9-.2 1.9.1 2.8.1.1 0 .3-.1.4l-1.2 1.2c-.1.1-.1.1-.2.1-1.3.1-2.5.2-3.8.1-1.4-.1-2.7-.3-4-.7-.9-.2-1.7-.7-2.4-1.3-.4-.4-.6-1-.6-1.5 0-2.3 1-4.5 2.7-6.1.4-.4.8-.7 1.2-1z"/>
        <path class="ast0"
              d="M15 13.199c3.4 3.7 9.1 4 12.8.6l.6-.6c1.6 1 2.9 2.3 3.9 3.8-.3.1-.6.3-1 .4-2 .9-3.4 2.6-3.9 4.7-.4 1.4-.3 2.9.2 4.2.1.2 0 .4-.1.6l-1.8 1.8c-.1.1-.2.2-.3.2-1.9.2-3.8.2-5.6.1-2-.1-4-.4-6-1-1.3-.4-2.5-1-3.5-1.9-.6-.6-1-1.4-1-2.3 0-3.5 1.5-6.8 4-9.1.4-.6 1.1-1 1.7-1.5zM13.2 10.899c0 2.7-2.2 4.8-4.8 4.8-2.7 0-4.8-2.2-4.8-4.8 0-2.7 2.2-4.8 4.8-4.8 2.7 0 4.8 2.2 4.8 4.8z"/>
        <path class="ast0" d="M28.9 7.199c0 4-3.2 7.2-7.2 7.2s-7.2-3.2-7.2-7.2 3.2-7.2 7.2-7.2 7.2 3.2 7.2 7.2z"/>
      </symbol>
      <symbol id="template_icon" viewBox="0 0 31.1 32.5" xmlns="http://www.w3.org/2000/svg">
        <path class="ast0"
              d="M22.998 31.702h-20.3c-.6 0-1.1-.2-1.6-.6-.4-.5-.7-1.1-.6-1.7v-.1-22.3c-.1-1.1.6-2 1.6-2.3.2 0 .4-.1.6-.1h3.4v-.7c-.1-.6.3-1 .8-1.1H9.798c.1 0 .3-.1.3-.2.4-1.3 1.6-2.1 2.9-2.1 1.3 0 2.5.9 2.9 2.1 0 .1 0 .1.1.2h2.9c.5 0 .9.4 1 .9v1h3.2c1.2 0 2.1.9 2.1 2.1v4.7h-1v-4.6c.1-.6-.4-1.2-1-1.2H19.698V6.602c0 .4-.3.8-.8.9h-12c-.4 0-.7-.3-.8-.7 0-.2 0-.5-.1-.8v-.3h-3.3c-.6-.1-1.2.4-1.2 1v22.8c-.1.6.4 1.2 1.1 1.2H23.198c.6.1 1.2-.4 1.2-1V26.302h1v2.8c0 .3 0 .7-.1 1-.2.9-1 1.6-1.9 1.6h-.4zm-15.9-27.9v2.6h11.6v-2.6h-3.1c-.5 0-.6-.1-.7-.6-.1-.4-.3-.7-.5-1-.4-.5-.9-.7-1.5-.7-.2 0-.4 0-.6.1-.8.2-1.4.8-1.5 1.7-.1.5-.2.6-.7.6h-3z"/>
        <path class="ast0"
              d="M3.6 24.7h15.6v1H3.6zM3.6 21.2h13.2v1H3.6zM3.6 17.9h13.2v1H3.6zM3.6 14.5h15.6v1H3.6zM3.6 11.2h15.6v1H3.6z"/>
        <g class="ast1">
          <path class="ast0" d="M20.5 19.3v-1.6h3.4v-3.6h1.6v3.6h3.4v1.6h-3.4v3.8h-1.6v-3.8h-3.4z"/>
        </g>
      </symbol>
      <symbol id="cog_icon" viewBox="0 0 22.467 22.467" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.676 7.3l1.544-2.947-1.966-1.966-2.948 1.545a5.03 5.03 0 0 0-1.545-.562L12.638 0H9.829L8.706 3.23a5.868 5.868 0 0 0-1.4.562L4.353 2.247 2.247 4.353 3.791 7.3a5.868 5.868 0 0 0-.562 1.4L0 9.829v2.808l3.23 1.123c.14.562.421.983.562 1.545l-1.545 2.949 1.966 1.966 2.949-1.545a5.03 5.03 0 0 0 1.545.562l1.123 3.23h2.808l1.123-3.23c.562-.14.983-.421 1.545-.562l2.949 1.545 1.966-1.966-1.545-2.949a5.03 5.03 0 0 0 .562-1.545l3.23-1.123V9.829l-3.23-1.123a5.868 5.868 0 0 0-.562-1.406zm-7.442 8.144a4.137 4.137 0 0 1-4.213-4.213 4.137 4.137 0 0 1 4.213-4.213 4.137 4.137 0 0 1 4.213 4.213 4.137 4.137 0 0 1-4.214 4.215z"
          data-name="Path 108"/>
      </symbol>
      <symbol id="desktop_icon" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.186 15.027a9.817 9.817 0 0 1-.958 1.74c-.503.726-.915 1.228-1.233 1.507-.493.458-1.02.692-1.585.706-.406 0-.895-.117-1.464-.354-.572-.235-1.097-.352-1.577-.352-.503 0-1.043.117-1.62.352-.579.237-1.045.36-1.401.372-.542.024-1.082-.217-1.621-.724-.344-.303-.775-.823-1.29-1.56-.553-.787-1.008-1.699-1.364-2.739-.382-1.123-.573-2.21-.573-3.264 0-1.206.258-2.247.774-3.118.406-.7.946-1.253 1.622-1.659A4.328 4.328 0 0 1 7.09 5.31c.43 0 .995.134 1.696.399.7.265 1.148.4 1.345.4.147 0 .646-.158 1.492-.471.8-.291 1.475-.412 2.027-.364 1.499.122 2.624.72 3.373 1.795-1.34.82-2.003 1.97-1.99 3.444.012 1.149.425 2.104 1.235 2.863.367.353.777.625 1.233.818-.099.29-.203.568-.314.834zM13.749 1.36c0 .9-.325 1.74-.973 2.519-.783.925-1.73 1.46-2.756 1.375a2.832 2.832 0 0 1-.021-.341c0-.865.372-1.79 1.033-2.546.33-.382.75-.7 1.259-.955.508-.25.988-.388 1.44-.412.013.12.018.24.018.36z">
        </path>
      </symbol>
      <symbol id="camera_icon" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.414 5H16a3 3 0 0 1 3 3v7a3 3 0 0 1-3 3H4a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3h1.586l1.707-1.707A1 1 0 0 1 8 3h4a1 1 0 0 1 .707.293L14.414 5zm-6 0L6.707 6.707A1 1 0 0 1 6 7H4a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1h-2a1 1 0 0 1-.707-.293L11.586 5H8.414zM10 15a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z">
        </path>
      </symbol>
      <symbol id="dashboard_icon" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.5 5a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.5-.5h-13zM11 16v1h2a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2h2v-1H3.5A2.5 2.5 0 0 1 1 13.5v-8A2.5 2.5 0 0 1 3.5 3h13A2.5 2.5 0 0 1 19 5.5v8a2.5 2.5 0 0 1-2.5 2.5H11z">
        </path>
      </symbol>
      <symbol id="document" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13 4.414V6h1.586L13 4.414zM16 8h-4a1 1 0 0 1-1-1V3H5.5a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8zm-4-7a1 1 0 0 1 .707.293l5 5A1 1 0 0 1 18 7v9.5a2.5 2.5 0 0 1-2.5 2.5h-10A2.5 2.5 0 0 1 3 16.5v-13A2.5 2.5 0 0 1 5.5 1H12zm2 9a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2h7zm0 3a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2h7z">
        </path>
      </symbol>
      <symbol id="nav--payroll" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19 7a1 1 0 0 1 0-2h3c1.758 0 3 1.304 3 3v12c0 1.696-1.242 3-3 3H4c-1.758 0-3-1.304-3-3V8c0-1.696 1.242-3 3-3h3a1 1 0 1 1 0 2H4c-.631 0-1 .387-1 1v12c0 .613.369 1 1 1h18c.631 0 1-.387 1-1V8c0-.613-.369-1-1-1h-3zm1 7a1 1 0 0 1 0 2h-6a1 1 0 0 1 0-2h6zM8 18a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3-13h4a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2zm0 2v2h4V5h-4z">
        </path>
      </symbol>
      <symbol id="reports_icons" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3 13h4a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1zm1 8h2v-6H4v6zM19 3h4a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm1 18h2V5h-2v16zM11 8h4a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1zm1 13h2V10h-2v11z">
        </path>
      </symbol>
      <symbol id="open-menu" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.84 7.772c.348-.426.175-.772-.376-.772H6.559c-.556 0-.727.342-.377.772l3.2 3.928c.35.426.91.43 1.26 0l3.2-3.928h-.001z">
        </path>
      </symbol>
      <symbol id="refresh" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.346 7H7.5a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1V3.5a1 1 0 1 1 2 0v1.989c.157-.191.389-.473.48-.581C6.137 2.933 7.741 2 10.5 2c3.03 0 5.507 1.524 7.348 4.47a1 1 0 1 1-1.696 1.06C14.66 5.142 12.803 4 10.5 4c-2.09 0-3.178.633-4.49 2.194-.12.143-.538.655-.62.752L5.347 7zm9.308 6H12.5a1 1 0 0 1 0-2H17a1 1 0 0 1 1 1v4.5a1 1 0 0 1-2 0v-1.989c-.157.192-.389.474-.48.581C13.863 17.068 12.259 18 9.5 18c-3.03 0-5.507-1.524-7.348-4.47a1 1 0 1 1 1.696-1.06C5.34 14.858 7.197 16 9.5 16c2.09 0 3.178-.633 4.49-2.194.12-.143.539-.655.62-.752l.044-.054z">
        </path>
      </symbol>
      <symbol id="expand" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 12.586l6.293-6.293a1 1 0 0 1 1.414 1.414l-7 7a1 1 0 0 1-1.414 0l-7-7a1 1 0 0 1 1.414-1.414L10 12.586z">
        </path>
      </symbol>
      <symbol id="search_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path d="M22 20L20 22 14 16 14 14 16 14z"/>
        <path
          d="M9,16c-3.9,0-7-3.1-7-7c0-3.9,3.1-7,7-7c3.9,0,7,3.1,7,7C16,12.9,12.9,16,9,16z M9,4C6.2,4,4,6.2,4,9c0,2.8,2.2,5,5,5 c2.8,0,5-2.2,5-5C14,6.2,11.8,4,9,4z"/>
        <path d="M13.7 12.5H14.7V16H13.7z" transform="rotate(-44.992 14.25 14.25)"/>
      </symbol>
    </svg>
  </div>

  <button id="openTicketButton"  [hidden]="true"
          data-toggle="modal"  (click)="openViewTicketModal(viewTicketModal)" ></button>

</div>

<ng-template #viewTicketModal>

  <ticket-view (closeEvent)="closeViewModal()" (outputOpenTicketEvent)="openTicketPage($event)" [setNotesEditable]="true" [ticketId]="ticketId"></ticket-view>

</ng-template>


<!-- <navbar-cmp></navbar-cmp> -->

<!-- <footer-cmp></footer-cmp> -->
